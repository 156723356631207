import {
  BarChartOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  FlagOutlined,
  IdcardOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  RetweetOutlined,
  StarOutlined,
  TagsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined
} from '@ant-design/icons';
import { isBoxVersion } from 'core/config';
import { CaseIcon } from 'images/CaseIcon';
import Icon from 'components/Icon';
import React from 'react';

export const DEFAULT_PAGE_TITLE = 'constants.defaultPageTitle';

export const RATING_CALCULATION = {
  SUM: 'sum',
  AVERAGE: 'average'
};

export const RATING_CALCULATION_LITERAL = {
  sum: 'constants.ratingCalculations.sum',
  average: 'constants.ratingCalculations.average'
};

export const RATING_METHOD = {
  SIMPLE: 'simple',
  WEIGHTED: 'weighted'
};

export const RATING_METHOD_LITERAL = {
  weighted: 'constants.ratingMethods.weighted',
  simple: 'constants.ratingMethods.simple'
};

export const RATING_MODE = {
  NUMBERS: 'numbers',
  PERCENTAGE: 'percentage'
};

export const THEME_COLORS_LITERALS = {
  RED: 'red',
  GREEN: 'green',
  YELLOW: 'yellow',
  GRAY: 'gray'
};

export const FLAGS_COLORS_VALUES = {
  red: 'red',
  green: 'green',
  yellow: 'yellow',
  without_flag: ''
};

export const FLAGS_COLORS_LITERALS_RUS = {
  red: 'constants.flagsColors.red',
  green: 'constants.flagsColors.green',
  yellow: 'constants.flagsColors.yellow',
  without_flag: 'constants.flagsColors.withoutFlag'
};

export const FLAGS_TO_COLORS_RELATIONS = {
  red: 'var(--red_primary)',
  yellow: 'var(--orange_primary)',
  green: 'var(--green_primary)',
  without_flag: 'var(--black_4)'
};

export const COMMENTS_FLAGS_COLORS_RELATIONS = {
  red: 'var(--red_2)',
  yellow: 'var(--orange_2)',
  green: 'rgba(189, 236, 158, 0.44)',
  without_flag: 'var(--gray_primary)'
};

export const WIDGET_FLAGS_COLORS_RELATIONS = {
  red: 'var(--red_4)',
  yellow: 'var(--orange_8)',
  green: 'var(--green_4)',
  without_flag: 'var(--gray_8)'
};

export const DISABLED_FLAGS_TO_COLORS_RELATIONS = {
  red: 'var(--red_2)',
  yellow: 'var(--orange_4)',
  green: 'var(--green_2)'
};

export const BOX_VERSION_INTEGRATIONS_LIST = ['custom_http_telephony', 'custom_http_text'];
// TODO: refactor: list to types
export const ALL_INTEGRATIONS_LIST = {
  UPLOAD_PHONE_CALLS: 'upload_phone_calls',
  CUSTOM_HTTP_TELEPHONY: 'custom_http_telephony',
  CUSTOM_HTTP_TEXT: 'custom_http_text',
  BITRIX_CRM: 'bitrix_crm',
  AMO_CRM: 'amocrm'
};

export const AVAILABLE_INTEGRATIONS_LIST = isBoxVersion
  ? BOX_VERSION_INTEGRATIONS_LIST
  : Object.values(ALL_INTEGRATIONS_LIST);

export const LINK_MANUAL_ALL_INTEGRATIONS =
  'https://qolio.notion.site/5f469c88c7974ca4b6af39cd1c8aef12';

// TODO: refactor: start using constant types
export const INTEGRATIONS = {
  custom_http_telephony: {
    name: 'constants.integrations.customHttpTelephony.title',
    description: 'constants.integrations.customHttpTelephony.description',
    type: 'custom_http_telephony',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/podklyuchenie-po-api',
    requiredFields: []
  },
  custom_http_text: {
    name: 'constants.integrations.customHttpText.title',
    description: 'constants.integrations.customHttpText.description',
    type: 'custom_http_text',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/podklyuchenie-po-api',
    requiredFields: []
  },
  bitrix_crm: {
    name: 'constants.integrations.bitrixCrm.title',
    description: 'constants.integrations.bitrixCrm.description',
    type: 'bitrix_crm',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/bitriks-24',
    requiredFields: ['bitrixDomain']
  },
  amocrm: {
    name: 'constants.integrations.amoCrm.title',
    description: 'constants.integrations.amoCrm.description',
    type: 'amocrm',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/amocrm',
    requiredFields: []
  },
  sipuni: {
    name: 'constants.integrations.sipuni.title',
    description: 'constants.integrations.sipuni.description',
    type: 'sipuni',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/sipuni',
    requiredFields: ['clientId', 'clientSecret']
  },
  mango: {
    name: 'constants.integrations.mango.title',
    description: 'constants.integrations.mango.description',
    type: 'mango',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/mango-office',
    requiredFields: ['clientId', 'clientSecret']
  },
  usedesk: {
    name: 'Usedesk',
    description: 'Usedesk',
    type: 'usedesk',
    endpoint: 'https://api.usedesk.ru',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/usedesk',
    requiredFields: ['endpoint', 'clientSecret']
  },
  binotel: {
    name: 'Binotel',
    description: 'Binotel',
    type: 'binotel',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/binotel',
    requiredFields: ['clientId', 'clientSecret']
  },
  zendesk: {
    name: 'Zendesk',
    description: 'Zendesk',
    type: 'zendesk',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/zendesk',
    requiredFields: ['clientId', 'endpoint', 'accessToken']
  },
  help_desk_eddy: {
    name: 'HelpDeskEddy',
    description: 'HelpDeskEddy',
    type: 'help_desk_eddy',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/helpdeskeddy',
    requiredFields: ['endpoint', 'clientSecret']
  },
  infinity: {
    name: 'Infinity X',
    description: 'Infinity X',
    type: 'infinity',
    linkToManual: null,
    requiredFields: ['endpoint']
  },
  bright_pattern: {
    name: 'Bright Pattern',
    description: 'Bright Pattern',
    type: 'bright_pattern',
    linkToManual: null,
    requiredFields: [
      'endpoint',
      'clientSecret',
      'clientId',
      'mysqlPort',
      'mysqlHost',
      'mysqlUsername',
      'mysqlPassword',
      'mysqlDatabase'
    ]
  },
  uis: {
    name: 'UIS/Comagic',
    description: 'UIS/Comagic',
    type: 'uis',
    endpoint: ['https://dataapi.uiscom.ru', 'https://dataapi.comagic.ru'],
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/uis-comagic',
    requiredFields: ['endpoints', 'clientSecret']
  },
  naumen: {
    name: 'Naumen',
    description: 'Naumen',
    type: 'naumen',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/naumen',
    requiredFields: [
      'username',
      'endpoint',
      'password',
      'mysqlPort',
      'mysqlHost',
      'mysqlUsername',
      'mysqlPassword',
      'mysqlDatabase'
    ]
  },
  edna: {
    name: 'Edna',
    description: 'Edna',
    type: 'edna',
    linkToManual: 'https://wiki.qolio.io/qolio-or-baza-znanii/integracii/edna',
    requiredFields: ['username', 'endpoint', 'password']
  }
};

export const INTEGRATIONS_SYNC_PERIODS = {
  MONTH: {
    value: 1,
    name: 'constants.integrationsSyncPeroids.month'
  },
  THREE_MONTH: {
    value: 3,
    name: 'constants.integrationsSyncPeroids.threeMonth'
  },
  SIX_MONTH: {
    value: 6,
    name: 'constants.integrationsSyncPeroids.sixMonth'
  },
  ALL_TIME: {
    value: 120,
    name: 'constants.integrationsSyncPeroids.allTime'
  }
};

export const ERRORS = {
  UNKNOWN_ERROR: 'constants.errors.unknownError',
  LOADING_RECORD_ERROR: 'constants.errors.loadingRecordError'
};

export const CHECKLIST_MANAGER_STATES = {
  SAVED: 'saved',
  EDITING: 'editing',
  INITIAL: 'initial'
};

export const CALL_DIRECTION = {
  INCOMING: 'incoming',
  OUTCOMING: 'outcoming'
};

export const CALL_DIRECTION_LITERALS = {
  incoming: 'constants.clientInteractionDirections.incoming',
  outcoming: 'constants.clientInteractionDirections.outcoming'
};

export const TASKS_STATUS = {
  UNASSIGNED: 'unassigned',
  TODO: 'todo',
  IN_PROGRESS: 'inProgress',
  DONE: 'done',
  ARCHIVED: 'archived'
};

export const CREATE_CHECKLIST_MODAL_TABS = {
  NOTIFY: 'notify',
  ASSIGN: 'assign'
};

export const TASK_DEFINITIONS_TYPES = {
  AUTOMATIC: 'automatic',
  MANUAL: 'manual'
};

export const TASK_PRIORITY_HOURS = {
  LOW: 48,
  MEDIUM: 6,
  HIGH: 2,
  HIGHEST: 1
};

export const PRIORITY_TYPES = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  HIGHEST: 'highest'
};

export const BITRIX_INTEGRATION_PLACEMENTS = {
  CRM_LEAD_DETAIL_TAB: 'CRM_LEAD_DETAIL_TAB',
  CRM_DEAL_DETAIL_TAB: 'CRM_DEAL_DETAIL_TAB',
  CRM_ANALYTICS_MENU: 'CRM_ANALYTICS_MENU'
};

export const CLIENT_INTERACTIONS_TYPES = {
  PHONE_CALL: 'phone_call',
  EMAIL: 'email',
  CHAT: 'chat',
  TICKET: 'ticket',
  VIDEO: 'video',
  OTHER: 'other'
};

export const CLIENT_INTERACTIONS_TYPES_LITERALS = {
  phone_call: 'constants.clientInteractionsTypes.phoneCall',
  email: 'constants.clientInteractionsTypes.email',
  chat: 'constants.clientInteractionsTypes.chat',
  ticket: 'constants.clientInteractionsTypes.ticket',
  video: 'constants.clientInteractionsTypes.video',
  other: 'constants.clientInteractionsTypes.other'
};

export const COMMUNICATION_TYPES = {
  PHONE_CALL: 'phone_call',
  TEXT: 'text_communication',
  CUSTOM: 'custom_communication'
};

export const BACKGROUND_JOB_STATUSES = {
  QUEUED: 'queued',
  PENDING: 'pending',
  RUNNING: 'running',
  DONE: 'done',
  FAILED: 'failed'
};

export const SYMBOLIC_TIME_RANGE_LITERALS = {
  all: 'constants.symbolicTimeRanges.all',
  today: 'constants.symbolicTimeRanges.today',
  yesterday: 'constants.symbolicTimeRanges.yesterday',
  last_seven_days: 'constants.symbolicTimeRanges.lastSevenDays',
  this_week: 'constants.symbolicTimeRanges.thisWeek',
  previous_week: 'constants.symbolicTimeRanges.previousWeek',
  last_thirty_days: 'constants.symbolicTimeRanges.lastThirtyDays',
  this_month: 'constants.symbolicTimeRanges.thisMonth',
  previous_month: 'constants.symbolicTimeRanges.previousMonth',
  this_year: 'constants.symbolicTimeRanges.thisYear',
  previous_year: 'constants.symbolicTimeRanges.previousYear'
};

export const DATE_PICKER_MODES = {
  clientInteraction: 'clientInteraction',
  review: 'review',
  custom: {
    appellation: 'appellation',
    customFields: 'customFields',
    calibrationSession: 'calibrationSession'
  },
  default: 'default'
};

export const DATE_PICKER_MODES_CUSTOM_KEYS = {
  appellation: {
    symbolicTimeRange: 'appellationCreatedAtSymbolicTimeRange',
    timeFrom: 'appellationCreatedAtTimeFrom',
    timeTo: 'appellationCreatedAtTimeTo'
  },
  customFields: {
    symbolicTimeRange: 'symbolicTimeRange',
    timeFrom: 'timeFrom',
    timeTo: 'timeTo'
  },
  calibrationSession: {
    symbolicTimeRange: 'calibrationSessionSymbolicTimeRange',
    timeFrom: 'calibrationSessionTimeFrom',
    timeTo: 'calibrationSessionTimeTo'
  }
};

export const DATE_PICKER_MODE_LITERAL = {
  clientInteraction: 'constants.symbolicMode.clientInteraction',
  review: 'constants.symbolicMode.review'
};

export const SYMBOLIC_TIME_RANGE = {
  ALL_TIME: 'all',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_SEVEN_DAYS: 'last_seven_days',
  THIS_WEEK: 'this_week',
  PREVIOUS_WEEK: 'previous_week',
  LAST_THIRTY_DAYS: 'last_thirty_days',
  THIS_MONTH: 'this_month',
  PREVIOUS_MONTH: 'previous_month',
  THIS_YEAR: 'this_year',
  PREVIOUS_YEAR: 'previous_year'
};

export const CONVERT_RATES = { USD: 1, RUB: 75, BYN: 2.5, UAH: 110, KZT: 440 };

export const PLANS_DASHBOARDS_COUNT_LIMIT = {
  free: 20,
  start: 20,
  profi: 999
};

export const PLANS_LITERALS = {
  free: {
    name: 'constants.plans.free',
    value: 'free'
  },
  start: {
    name: 'constants.plans.start',
    value: 'start'
  },
  profi: {
    name: 'constants.plans.profi',
    value: 'profi'
  },
  notInstalled: {
    name: 'constants.plans.notInstalled',
    value: 'notInstalled'
  }
};

export const PERIODS = { MONTH: 1, YEAR: 12 };

export const AMO_COMMANDS = {
  ASK_AMO_SETTINGS: 'DEALAPP_ASK_AMO_SETTINGS',
  SEND_AMO_SETTINGS: 'DEALAPP_SEND_AMO_SETTINGS',
  ASK_USER_DATA: 'DEALAPP_ASK_USER_DATA',
  SEND_USER_DATA: 'DEALAPP_SEND_USER_DATA'
};

export const PERMISSIONS = {
  CAN_PERFORM_CLIENT_INTERACTION: 'can_perform_client_interactions',
  CAN_SIGN_IN: 'can_sign_in',
  CAN_SEE_AND_MANAGE_HIDDEN_COMMENTS: 'can_see_and_manage_hidden_comments',
  CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB: 'can_access_user_analytics_page/operators/charts_tab',
  CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB:
    'can_access_user_analytics_page/operators/reviews_tab',
  CAN_ACCESS_USER_ANALYTICS_PAGE_COMMENTS_TAB:
    'can_access_user_analytics_page/operators/comments_tab',
  CAN_REPLY_COMMENTS: 'can_reply_comments',
  CAN_REPLY_ALL_COMMENTS: 'can_comment_any_communication',
  CAN_ACCESS_LIBRARY: 'can_access_library',
  CAN_ADD_LIBRARY_ITEMS: 'can_add_library_items',
  CAN_SEE_UNIT_CLIENT_INTERACTIONS: 'can_see_unit_client_interactions',
  CAN_DOWNLOAD_CLIENT_INTERACTION_MEDIA: 'can_download_client_interaction_media',
  CAN_MAKE_REVIEW: 'can_make_review',
  CAN_SEE_UNIT_REVIEWS: 'can_see_unit_reviews',
  CAN_SEE_AND_UPDATE_ASSIGNED_TASKS: 'can_see_and_update_assigned_tasks',
  CAN_MANAGE_TASKS: 'can_manage_tasks',
  CAN_MANAGE_UNIT_TASKS: 'can_manage_unit_tasks',
  CAN_MANAGE_LIBRARY: 'can_manage_library',
  CAN_MANAGE_CHECKLIST_DEFINITIONS: 'can_manage_checklist_definitions',
  CAN_MANAGE_TASK_DEFINITIONS: 'can_manage_task_definitions',
  CAN_MANAGE_ORGANIZATION_STRUCTURE: 'can_manage_organization_structure',
  CAN_MANAGE_ORGANIZATION_SETTINGS: 'can_manage_organization_settings',
  CAN_MANAGE_INTEGRATIONS: 'can_manage_integrations',
  CAN_SEE_DASHBOARDS: 'can_see_dashboards',
  CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE: 'can_see_unit_operators_analytics_page',
  CAN_ACCESS_TEAM_PAGE: 'can_access_team_page',
  CAN_MANAGE_CALIBRATION_SESSIONS: 'can_manage_calibration_sessions',
  CAN_SEE_REVIEWER_NAME: 'can_see_reviewer_name',
  CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS: 'can_manage_work_plan_task_configurations',
  CAN_CREATE_APPELLATIONS: 'can_create_appellations',
  CAN_WATCH_APPELLATIONS: 'can_watch_appellations',
  CAN_ADMINISTRATE_APPELLATIONS: 'can_administrate_appellations',
  CAN_SEE_UNIT_APPELLATIONS: 'can_see_unit_appellations',
  CAN_ACCESS_USER_ANALYTICS_PAGE_OPERATORS_APPELLATIONS_TAB:
    'can_access_user_analytics_page/operators/appellations_tab',
  CAN_ACCESS_APPELLATIONS_ANALYTICS_PAGE: 'can_access_appellations_analytics_page',
  CAN_UPDATE_CLIENT_INTERACTION_OPERATOR: 'can_update_client_interaction_operator',
  CAN_SEE_USER_CUSTOM_FIELDS: 'can_see_user_custom_fields',
  CAN_AI_TRANSCRIBE_CLIENT_INTERACTIONS: 'can_ai_transcribe_client_interactions',
  CAN_AI_ANALYZE_CLIENT_INTERACTIONS: 'can_ai_analyze_client_interactions',
  CAN_EDIT_REVIEW: 'can_edit_reviews'
};

export const PERMISSIONS_DESCRIPTIONS = {
  CAN_PERFORM_CLIENT_INTERACTION: {
    title: 'constants.permissionsDescriptions.canPerformClientInteraction.title',
    description: ''
  },
  CAN_SIGN_IN: {
    title: 'constants.permissionsDescriptions.canSignIn.title',
    description: ''
  },
  CAN_SEE_AND_MANAGE_HIDDEN_COMMENTS: {
    title: 'constants.permissionsDescriptions.canSeeAndManageHiddenComments.title',
    description: ''
  },
  CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB: {
    title: 'constants.permissionsDescriptions.canAccessUserAnalyticsPageChartsTab.title',
    description: 'constants.permissionsDescriptions.canAccessUserAnalyticsPageChartsTab.description'
  },
  CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB: {
    title: 'constants.permissionsDescriptions.canAccessUserAnalyticsPageReviewsTab.title',
    description:
      'constants.permissionsDescriptions.canAccessUserAnalyticsPageReviewsTab.description'
  },
  CAN_ACCESS_USER_ANALYTICS_PAGE_COMMENTS_TAB: {
    title: '',
    description: ''
  },
  CAN_REPLY_COMMENTS: {
    title: 'constants.permissionsDescriptions.canReplyCommnets.title',
    description: 'constants.permissionsDescriptions.canReplyCommnets.description'
  },
  CAN_REPLY_ALL_COMMENTS: {
    title: 'constants.permissionsDescriptions.canReplyAnyoneComments.title',
    description: 'constants.permissionsDescriptions.canReplyAnyoneComments.description'
  },
  CAN_ACCESS_LIBRARY: {
    title: 'constants.permissionsDescriptions.canAccessLibrary.title',
    description: ''
  },
  CAN_ADD_LIBRARY_ITEMS: {
    title: 'constants.permissionsDescriptions.canAddLibraryItems.title',
    description: ''
  },
  CAN_SEE_UNIT_CLIENT_INTERACTIONS: {
    title: 'constants.permissionsDescriptions.canSeeUnitClientInteractions.title',
    description: ''
  },
  CAN_DOWNLOAD_CLIENT_INTERACTION_MEDIA: {
    title: 'constants.permissionsDescriptions.canDownloadClientInteraction.title',
    description: ''
  },
  CAN_MAKE_REVIEW: {
    title: 'constants.permissionsDescriptions.canMakeReview.title',
    description: ''
  },
  CAN_SEE_UNIT_REVIEWS: {
    title: 'constants.permissionsDescriptions.canSeeUnitReviews.title',
    description: ''
  },
  CAN_SEE_AND_UPDATE_ASSIGNED_TASKS: {
    title: 'constants.permissionsDescriptions.canSeeAndUpdateAssignedTasks.title',
    description: ''
  },
  CAN_MANAGE_TASKS: {
    title: 'constants.permissionsDescriptions.canManageTasks.title',
    description: ''
  },
  CAN_MANAGE_UNIT_TASKS: {
    title: 'constants.permissionsDescriptions.canManageUnitTasks.title',
    description: ''
  },
  CAN_MANAGE_LIBRARY: {
    title: 'constants.permissionsDescriptions.canManageLibrary.title',
    description: 'constants.permissionsDescriptions.canManageLibrary.description'
  },
  CAN_MANAGE_CHECKLIST_DEFINITIONS: {
    title: 'constants.permissionsDescriptions.canManageChecklistDefinitions.title',
    description: ''
  },
  CAN_MANAGE_TASK_DEFINITIONS: {
    title: 'constants.permissionsDescriptions.canManageTaskDefinitions.title',
    description: ''
  },
  CAN_MANAGE_ORGANIZATION_STRUCTURE: {
    title: 'constants.permissionsDescriptions.canManageOrganizationStructure.title',
    description: ''
  },
  CAN_MANAGE_ORGANIZATION_SETTINGS: {
    title: 'constants.permissionsDescriptions.canManageOrganizationSettings.title',
    description: ''
  },
  CAN_MANAGE_INTEGRATIONS: {
    title: 'constants.permissionsDescriptions.canManageIntegrations.title',
    description: ''
  },
  CAN_SEE_DASHBOARDS: {
    title: 'constants.permissionsDescriptions.canSeeDashboards.title',
    description: ''
  },
  CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE: {
    title: 'constants.permissionsDescriptions.canSeeUnitOperatorsAnalyticsPage.title',
    description: ''
  },
  CAN_ACCESS_TEAM_PAGE: {
    title: 'constants.permissionsDescriptions.canAccessTeamPage.title',
    description: ''
  },
  CAN_MANAGE_CALIBRATION_SESSIONS: {
    title: 'constants.permissionsDescriptions.canManageCalibrationSessions.title',
    description: ''
  },
  CAN_SEE_REVIEWER_NAME: {
    title: 'constants.permissionsDescriptions.canSeeReviewerName.title',
    description: ''
  },
  CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS: {
    title: 'constants.permissionsDescriptions.canManageWorkPlanTaskConfigurations.title',
    description: ''
  },
  CAN_CREATE_APPELLATIONS: {
    title: 'constants.permissionsDescriptions.canCreateAppellations.title',
    description: ''
  },
  CAN_ADMINISTRATE_APPELLATIONS: {
    title: 'constants.permissionsDescriptions.canAdministrateAppellations.title',
    description: ''
  },
  CAN_WATCH_APPELLATIONS: {
    title: 'constants.permissionsDescriptions.canWatchAppellations.title',
    description: ''
  },
  CAN_SEE_UNIT_APPELLATIONS: {
    title: 'constants.permissionsDescriptions.canSeeUnitAppellations.title',
    description: ''
  },
  CAN_ACCESS_USER_ANALYTICS_PAGE_OPERATORS_APPELLATIONS_TAB: {
    title:
      'constants.permissionsDescriptions.canAccessUserAnalyticsPageOperatorsAppellationsTab.title',
    description: ''
  },
  CAN_ACCESS_APPELLATIONS_ANALYTICS_PAGE: {
    title: 'constants.permissionsDescriptions.canAccessAppellationsAnalyticsPage.title',
    description: ''
  },
  CAN_UPDATE_CLIENT_INTERACTION_OPERATOR: {
    title: 'constants.permissionsDescriptions.canUpdateClientInteractionOperator.title',
    description: ''
  },
  CAN_AI_ANALYZE_CLIENT_INTERACTIONS: {
    title: 'constants.permissionsDescriptions.canAiAnalyzeClientInteractions.title',
    description: ''
  },
  CAN_AI_TRANSCRIBE_CLIENT_INTERACTIONS: {
    title: 'constants.permissionsDescriptions.canAiTranscribeClientInteractions.title',
    description: ''
  },
  CAN_EDIT_REVIEW: {
    title: 'constants.permissionsDescriptions.canEditReview.title',
    description: ''
  }
};

export const NOTIFICATIONS_TYPES_LITERALS = {
  review_created: 'constants.notificationsTypes.reviewCreated',
  reply_comment_created: 'constants.notificationsTypes.replyCommentCreated'
  // task_created:  'constants.notificationsTypes.taskCreated'
};

export const SYNC_DATA_PERIODS = {
  THREE_DAYS: {
    number: 3,
    momentUnit: 'days',
    name: 'constants.syncDataPeriods.threeDays',
    key: 'THREE_DAYS'
  },
  WEEK: {
    number: 1,
    momentUnit: 'weeks',
    name: 'constants.syncDataPeriods.week',
    key: 'WEEK'
  },
  TWO_WEEKS: {
    number: 2,
    momentUnit: 'weeks',
    name: 'constants.syncDataPeriods.twoWeeks',
    key: 'TWO_WEEKS'
  },
  MONTH: {
    number: 1,
    momentUnit: 'months',
    name: 'constants.syncDataPeriods.month',
    key: 'MONTH'
  },
  THREE_MONTHS: {
    number: 3,
    momentUnit: 'months',
    name: 'constants.syncDataPeriods.threeMonths',
    key: 'THREE_MONTHS'
  },
  SIX_MONTHS: {
    number: 6,
    momentUnit: 'months',
    name: 'constants.syncDataPeriods.sixMonths',
    key: 'SIX_MONTHS'
  },
  ALL_TIME: {
    number: 120,
    momentUnit: 'months',
    name: 'constants.syncDataPeriods.allTime',
    key: 'ALL_TIME'
  }
};

// ! перепроверить работоспособность
const COLOR_ZONES_TEXT = {
  low: 'constants.colorZonesText.low',
  medium: 'constants.colorZonesText.medium',
  high: 'constants.colorZonesText.high'
};

export const ZONE_TO_COLOR_RELATIONS = {
  low: 'var(--red_4)',
  medium: 'var(--orange_8)',
  high: 'var(--green_4)',
  empty: 'var(--gray_8)'
};

export const COLOR_ZONE_LITERALS = {
  empty: 'constants.colorPercantageZone.emptyPercantage',
  high: 'constants.colorPercantageZone.highPercantage',
  medium: 'constants.colorPercantageZone.mediumPercantage',
  low: 'constants.colorPercantageZone.lowPercantage'
};

export const SCALE_TYPES = {
  max_2: {
    // type prop for BE
    type: 'max_2',
    key: 'max_2',
    max: 1,
    numbers: [0, 1],
    text: ['constants.scaleTypes.max_2.text.no', 'constants.scaleTypes.max_2.text.yes'],
    emojis: ['👎', '👍'],
    name: 'constants.scaleTypes.max_2.name',
    colorZones: {
      low: { stop: 0, text: COLOR_ZONES_TEXT.low },
      high: { stop: 1, text: COLOR_ZONES_TEXT.high }
    },
    valuesOptions: {
      emojis: { type: 'emojis', text: 'constants.scaleTypes.max_2.valuesOptions.emojis' },
      text: { type: 'text', text: 'constants.scaleTypes.max_2.valuesOptions.text' },
      numbers: { type: 'numbers', text: 'constants.scaleTypes.max_2.valuesOptions.numbers' }
    }
  },
  max_3: {
    type: 'max_3',
    key: 'max_3',
    max: 3,
    numbers: [1, 2, 3],
    emojis: ['🙁', '😐', '🙂'],
    colorZones: {
      low: { stop: 2, text: COLOR_ZONES_TEXT.low },
      medium: { stop: 3, text: COLOR_ZONES_TEXT.medium },
      high: { text: COLOR_ZONES_TEXT.high }
    },
    name: 'constants.scaleTypes.max_3.name',
    valuesOptions: {
      emojis: { type: 'emojis', text: '🙁 😐 🙂 (🙁 - 1, 😐 - 2, 🙂 - 3 )' },
      numbers: { type: 'numbers', text: 'constants.scaleTypes.max_3.valuesOptions.text' }
    }
  },
  max_4: {
    type: 'max_4',
    key: 'max_4',
    max: 4,
    numbers: [1, 2, 3, 4],
    emojis: ['🙁', '😐', '🙂', '😃'],
    colorZones: {
      low: { stop: 2, text: COLOR_ZONES_TEXT.low },
      medium: { stop: 3, text: COLOR_ZONES_TEXT.medium },
      high: { text: COLOR_ZONES_TEXT.high }
    },
    name: 'constants.scaleTypes.max_4.name',
    valuesOptions: {
      emojis: { type: 'emojis', text: '🙁 😐 🙂 😃 (🙁 - 1, 😐 - 2, 🙂 - 3, 😃 - 4)' },
      numbers: { type: 'numbers', text: '1, 2, 3, 4' }
    }
  },
  max_5: {
    type: 'max_5',
    key: 'max_5',
    max: 5,
    numbers: [1, 2, 3, 4, 5],
    emojis: ['😢', '🙁', '😐', '🙂', '😃'],
    colorZones: {
      low: { stop: 2, text: COLOR_ZONES_TEXT.low },
      medium: { stop: 4, text: COLOR_ZONES_TEXT.medium },
      high: { text: COLOR_ZONES_TEXT.high }
    },
    name: 'constants.scaleTypes.max_5.name',
    valuesOptions: {
      emojis: {
        type: 'emojis',
        text: '😢 🙁 😐 🙂 😃 (😢 - 1, 🙁 - 2, 😐 - 3, 🙂 - 4, 😃 - 5 )'
      },
      numbers: { type: 'numbers', text: '1, 2, 3, 4, 5' }
    }
  },
  max_10: {
    type: 'max_10',
    key: 'max_10',
    max: 10,
    numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    emojis: ['😡', '😭', '😢', '🙁', '🙄', '😐', '🙂', '😃', '😎', '😇'],
    colorZones: {
      low: { stop: 4, text: COLOR_ZONES_TEXT.low },
      medium: { stop: 8, text: COLOR_ZONES_TEXT.medium },
      high: { text: COLOR_ZONES_TEXT.high }
    },
    name: 'constants.scaleTypes.max_10.name',
    valuesOptions: {
      emojis: { type: 'emojis', text: '😡 😭 😢 🙁 🙄 😐 🙂 😃 😎 😇' },
      numbers: {
        type: 'numbers',
        text: 'constants.scaleTypes.max_10.valuesOptions.numbers'
      }
    }
  },
  custom: {
    type: 'custom',
    key: 'custom',
    name: 'constants.scaleTypes.custom.name',
    colorZones: {
      low: { text: COLOR_ZONES_TEXT.low, stop: 1 },
      medium: { text: COLOR_ZONES_TEXT.medium, stop: 1 },
      high: { text: COLOR_ZONES_TEXT.high }
    }
  },
  custom_with_views: {
    type: 'custom_with_views',
    key: 'custom_with_views',
    name: 'constants.scaleTypes.custom_with_views.name',
    colorZones: {
      low: { text: COLOR_ZONES_TEXT.low, stop: 1 },
      medium: { text: COLOR_ZONES_TEXT.medium, stop: 1 },
      high: { text: COLOR_ZONES_TEXT.high }
    }
  },
  custom_binary: {
    type: 'custom_binary',
    key: 'custom_binary',
    name: 'constants.scaleTypes.custom_binary.name',
    emojis: ['👍', '👎'],
    text: [
      'constants.scaleTypes.custom_binary.text.yes',
      'constants.scaleTypes.custom_binary.text.no'
    ],
    numbers: [0, 1],
    valuesOptions: {
      emojis: { type: 'emojis', text: '👍 и 👎' },
      text: { type: 'text', text: 'constants.scaleTypes.custom_binary.valuesOptions.text' },
      numbers: {
        type: 'numbers',
        text: 'constants.scaleTypes.custom_binary.valuesOptions.numbers'
      }
    },
    colorZones: {
      low: { text: COLOR_ZONES_TEXT.low, stop: 1 },
      medium: { text: COLOR_ZONES_TEXT.medium, stop: 1 },
      high: { text: COLOR_ZONES_TEXT.high }
    }
  }
};

export const PERCENTAGE_COLOR_ZONE = {
  low: {
    stop: SCALE_TYPES.max_10.colorZones.low.stop * 10,
    text: COLOR_ZONES_TEXT.low
  },
  medium: {
    stop: SCALE_TYPES.max_10.colorZones.medium.stop * 10,
    text: COLOR_ZONES_TEXT.medium
  },
  high: {
    text: COLOR_ZONES_TEXT.high
  }
};

export const CALIBRATION_SESSIONS_STATUS = {
  PENDING: {
    value: 'pending',
    text: 'constants.sessionsDirections.pending',
    key: 'PENDING',
    color: 'green'
  },
  DONE: {
    value: 'done',
    text: 'constants.sessionsDirections.done',
    key: 'DONE',
    color: 'red'
  }
};

export const CHECKLIST_DEFINITION_STATUS = {
  DRAFT: {
    value: 'draft',
    text: 'constants.checklistDefinitionStatuses.draft',
    key: 'DRAFT',
    color: undefined
  },
  ARCHIVED: {
    value: 'archived',
    text: 'constants.checklistDefinitionStatuses.archived',
    key: 'ARCHIVED',
    color: 'red'
  },
  PUBLISHED: {
    value: 'published',
    text: 'constants.checklistDefinitionStatuses.published',
    key: 'PUBLISHED',
    color: 'green'
  }
};

export const ANALYTICS_WIDGETS_NAMES = {
  RATING_FLAGS_COUNT_BY_OPERATORS: 'rating_flags_count_by_operators',
  CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS: 'checklist_definition_color_zones_by_operators',
  CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS:
    'checklist_definition_average_score_by_operators',
  REVIEWED_CLIENT_INTERACTION_COUNT_BY_OPERATORS: 'reviewed_client_interaction_count_by_operators',
  ANSWER_SCORES_COLOR_ZONE_BY_QUESTIONS: 'answer_scores_color_zone_by_questions',
  AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS: 'average_score_by_checklist_definitions',
  RATING_FLAGS_COUNT_BY_CHECKLIST_DEFINITIONS: 'rating_flags_count_by_checklist_definitions',
  REVIEWS_COUNT_BY_CHECKLIST_DEFINITIONS: 'reviews_count_by_checklist_definitions',
  CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS:
    'checklist_scores_color_zone_by_checklist_definitions',
  ANSWER_SCORES_AVERAGE_BY_QUESTIONS: 'answer_scores_average_by_questions',
  ANSWER_SCORES_COUNT_BY_QUESTIONS: 'answer_scores_count_by_questions',
  REVIEWS_COUNT_BY_OPERATORS: 'reviews_count_by_operators',
  COMMENTS_COUNT_BY_OPERATORS: 'comments_count_by_operators',
  ANSWER_SCORES_COLOR_ZONE_BY_QUESTIONS_HISTORY: 'answer_scores_color_zone_by_questions_history',
  REVIEWS_COUNT_BY_OPERATORS_HISTORY: 'reviews_count_by_operators_history',
  CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS_HISTORY:
    'checklist_scores_color_zone_by_checklist_definitions_history',
  AVERAGE_SCORE_BY_QUESTION_GROUPS: 'average_score_by_question_groups'
};

export const WIDGET_DATE_MODE_TO_STRING = {
  default: {
    symbolicTimeRange: 'clientInteractionSymbolicTimeRange',
    timeFrom: 'clientInteractionTimeFrom',
    timeTo: 'clientInteractionTimeTo'
  },
  clientInteraction: {
    symbolicTimeRange: 'clientInteractionSymbolicTimeRange',
    timeFrom: 'clientInteractionTimeFrom',
    timeTo: 'clientInteractionTimeTo'
  },
  review: {
    symbolicTimeRange: 'reviewSymbolicTimeRange',
    timeFrom: 'reviewTimeFrom',
    timeTo: 'reviewTimeTo'
  },
  appellation: {
    symbolicTimeRange: 'appellationCreatedAtSymbolicTimeRange',
    timeFrom: 'appellationCreatedAtTimeFrom',
    timeTo: 'appellationCreatedAtTimeTo'
  }
};

export const ACCESS_LEVELS = {
  NO_INVITATION_SENT: {
    name: 'constants.accessLevels.noInvitationSent',
    value: 'noInvitationSent'
  },
  INVITATION_SENT: {
    name: 'constants.accessLevels.invitationSent',
    value: 'invitationSent'
  },
  INVITATION_ACCEPTED: {
    name: 'constants.accessLevels.invitationAccepted',
    value: 'invitationAccepted'
  },
  USER_BLOCKED: {
    name: 'constants.accessLevels.userBlocked',
    value: 'userBlocked'
  }
};

export const USER_STATUSES = {
  ACTIVE: {
    name: 'constants.userStatuses.active',
    value: 'active'
  },
  DISABLED: {
    name: 'constants.userStatuses.disabled',
    value: 'disabled'
  }
};

export const WIDGETS_DESCRIPTIONS = {
  CHECKLIST_DEFINITION_AVERAGE_SCORE: {
    title: 'constants.widgetsDescriptions.checklistDefinitionAverageScore.title',
    description: 'constants.widgetsDescriptions.checklistDefinitionAverageScore.description'
  },
  PHONE_CALLS_COUNT: {
    title: 'constants.widgetsDescriptions.phoneCallsCount.title',
    description: 'constants.widgetsDescriptions.phoneCallsCount.description'
  },
  PHONE_CALLS_AVERAGE_DURATION: {
    title: 'constants.widgetsDescriptions.phoneCallsAverageDuration.title',
    description: 'constants.widgetsDescriptions.phoneCallsAverageDuration.description'
  },
  TOTAL_PHONE_CALLS_DURATION: {
    title: 'constants.widgetsDescriptions.totalPhoneCallsDuration.title',
    description: 'constants.widgetsDescriptions.totalPhoneCallsDuration.description'
  },
  REVIEWED_CLIENT_INTERACTIONS_DURATION: {
    title: 'constants.widgetsDescriptions.reviewedClientInteractionsDuration.title',
    description: 'constants.widgetsDescriptions.reviewedClientInteractionsDuration.description'
  },
  REVIEWED_CLIENT_INTERACTIONS_PERCENTAGE: {
    title: 'constants.widgetsDescriptions.reviewedClientInteractionsPercentage.title',
    description: 'constants.widgetsDescriptions.reviewedClientInteractionsPercentage.description'
  },
  CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_UNITS: {
    title: 'constants.widgetsDescriptions.checklistDefinitionAverageScoreByUnits.title',
    description: 'constants.widgetsDescriptions.checklistDefinitionAverageScoreByUnits.description'
  },
  CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS: {
    title: 'constants.widgetsDescriptions.checklistDefinitionAverageScoreByOperators.title',
    description:
      'constants.widgetsDescriptions.checklistDefinitionAverageScoreByOperators.description'
  },
  CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS: {
    title:
      'constants.widgetsDescriptions.checklistDefinitionAverageScoreByChecklistDefinitions.title',
    description:
      'constants.widgetsDescriptions.checklistDefinitionAverageScoreByChecklistDefinitions.description'
  },
  PHONE_CALLS_COUNT_BY_UNITS: {
    title: 'constants.widgetsDescriptions.phoneCallsCountByUnits.title',
    description: 'constants.widgetsDescriptions.phoneCallsCountByUnits.description'
  },
  PHONE_CALLS_COUNT_BY_OPERATORS: {
    title: 'constants.widgetsDescriptions.phoneCallsCountByOperators.title',
    description: 'constants.widgetsDescriptions.phoneCallsCountByOperators.description'
  },
  REVIEWS_COUNT_BY_REVIEWERS: {
    title: 'constants.widgetsDescriptions.reviewsCountByReviewers.title',
    description: 'constants.widgetsDescriptions.reviewsCountByReviewers.description'
  },
  RATING_FLAGS_COUNT: {
    title: 'constants.widgetsDescriptions.ratingFlagsCount.title',
    description: 'constants.widgetsDescriptions.ratingFlagsCount.description'
  },
  RATING_FLAGS_COUNT_BY_UNITS: {
    title: 'constants.widgetsDescriptions.ratingFlagsCountByUnits.title',
    description: 'constants.widgetsDescriptions.ratingFlagsCountByUnits.description'
  },
  RATING_FLAGS_COUNT_BY_OPERATORS: {
    title: 'constants.widgetsDescriptions.ratingFlagsCountByOperators.title',
    description: 'constants.widgetsDescriptions.ratingFlagsCountByOperators.description'
  },
  LABELS_COUNT_BY_OPERATORS: {
    title: 'constants.widgetsDescriptions.labelsCountByOperators.title',
    description: 'constants.widgetsDescriptions.labelsCountByOperators.description'
  },
  LABELS_COUNT_BY_UNITS: {
    title: 'constants.widgetsDescriptions.labelsCountByUnits.title',
    description: 'constants.widgetsDescriptions.labelsCountByUnits.description'
  },
  CHECKLIST_DEFINITION_QUESTIONS_AVERAGE_SCORES: {
    title: 'constants.widgetsDescriptions.checklistDefinitionQuestionsAverageScores.title',
    description:
      'constants.widgetsDescriptions.checklistDefinitionQuestionsAverageScores.description'
  },
  CHECKLIST_DEFINITION_QUESTIONS_AVERAGE_SCORES_BY_QUESTION_GROUPS: {
    title:
      'constants.widgetsDescriptions.checklistDefinitionQuestionsAverageScoresByQuestionGroups.title',
    description:
      'constants.widgetsDescriptions.checklistDefinitionQuestionsAverageScoresByQuestionGroups.description'
  },
  CHECKLIST_DEFINITION_QUESTION_GROUPS_AVERAGE_SCORES: {
    title: 'constants.widgetsDescriptions.checklistDefinitionQuestionGroupsAverageScores.title',
    description:
      'constants.widgetsDescriptions.checklistDefinitionQuestionGroupsAverageScores.description'
  },
  PHONE_CALLS_AVERAGE_DURATION_BY_UNITS: {
    title: 'constants.widgetsDescriptions.phoneCallsAverageDurationByUnits.title',
    description: 'constants.widgetsDescriptions.phoneCallsAverageDurationByUnits.description'
  },
  PHONE_CALLS_AVERAGE_DURATION_BY_OPERATORS: {
    title: 'constants.widgetsDescriptions.phoneCallsAverageDurationByOperators.title',
    description: 'constants.widgetsDescriptions.phoneCallsAverageDurationByOperators.description'
  },
  REVIEWS_COUNT_BY_REVIEWERS_HISTORY: {
    title: 'constants.widgetsDescriptions.reviewsCountByReviewersHistory.title',
    description: 'constants.widgetsDescriptions.reviewsCountByReviewersHistory.description'
  },
  CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS_HISTORY: {
    title: 'constants.widgetsDescriptions.checklistDefinitionAverageScoreByOperatorsHistory.title',
    description:
      'constants.widgetsDescriptions.checklistDefinitionAverageScoreByOperatorsHistory.description'
  },
  CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_UNITS_HISTORY: {
    title: 'constants.widgetsDescriptions.checklistDefinitionAverageScoreByUnitsHistory.title',
    description:
      'constants.widgetsDescriptions.checklistDefinitionAverageScoreByUnitsHistory.description'
  },
  AVERAGE_SCORE_BY_QUESTIONS_HISTORY: {
    title: 'constants.widgetsDescriptions.averageScoreByQuestionsHistory.title',
    description: 'constants.widgetsDescriptions.averageScoreByQuestionsHistory.description'
  },
  AVERAGE_SCORE_BY_QUESTION_GROUPS_HISTORY: {
    title: 'constants.widgetsDescriptions.averageScoreByQuestionGroupsHistory.title',
    description: 'constants.widgetsDescriptions.averageScoreByQuestionGroupsHistory.description'
  },
  PHONE_CALLS_AVERAGE_DURATION_BY_OPERATORS_HISTORY: {
    title: 'constants.widgetsDescriptions.phoneCallsAverageDurationByOperatorsHistory.title',
    description:
      'constants.widgetsDescriptions.phoneCallsAverageDurationByOperatorsHistory.description'
  },
  PHONE_CALLS_AVERAGE_DURATION_BY_UNITS_HISTORY: {
    title: 'constants.widgetsDescriptions.phoneCallsAverageDurationByUnitsHistory.title',
    description: 'constants.widgetsDescriptions.phoneCallsAverageDurationByUnitsHistory.description'
  },
  CLIENT_INTERACTIONS_COUNT_BY_OPERATORS_HISTORY: {
    title: 'constants.widgetsDescriptions.clientInteractionsCountByOperatorsHistory.title',
    description:
      'constants.widgetsDescriptions.clientInteractionsCountByOperatorsHistory.description'
  },
  CLIENT_INTERACTIONS_COUNT_BY_UNITS_HISTORY: {
    title: 'constants.widgetsDescriptions.clientInteractionsCountByUnitsHistory.title',
    description: 'constants.widgetsDescriptions.clientInteractionsCountByUnitsHistory.description'
  },
  TABLE_REVIEWS_COUNT_BY_OPERATORS: {
    title: 'constants.widgetsDescriptions.tableReviewsCountByOperators.title',
    description: 'constants.widgetsDescriptions.tableReviewsCountByOperators.description'
  },
  TABLE_REVIEWS_COUNT_BY_OPERATORS_WITH_KPI: {
    title: 'constants.widgetsDescriptions.tableReviewsCountByOperatorsWithKpi.title',
    description: 'constants.widgetsDescriptions.tableReviewsCountByOperatorsWithKpi.description'
  },
  TABLE_CHECKLIST_ITEMS_BY_OPERATORS: {
    title: 'constants.widgetsDescriptions.tableChecklistItemsByOperators.title',
    description: 'constants.widgetsDescriptions.tableChecklistItemsByOperators.description'
  },
  TABLE_CHECKLIST_ITEMS_HISTORY: {
    title: 'constants.widgetsDescriptions.tableChecklistItemsHistory.title',
    description: 'constants.widgetsDescriptions.tableChecklistItemsHistory.description'
  },
  FLAGS_AND_COMMENTS: {
    title: 'constants.widgetsDescriptions.flagsAndComments.title',
    description: 'constants.widgetsDescriptions.flagsAndComments.description'
  },
  TABLE_CHECKLISTS_SCORES_BY_OPERATORS: {
    title: 'constants.widgetsDescriptions.tableChecklistsScoresByOperators.title',
    description: 'constants.widgetsDescriptions.tableChecklistsScoresByOperators.description'
  },
  TABLE_REVIEWS_COUNT_BY_REVIEWERS: {
    title: 'constants.widgetsDescriptions.tableReviewsCountByReviewers.title',
    description: 'constants.widgetsDescriptions.tableReviewsCountByReviewers.description'
  },
  TABLE_REVIEWS_WITH_SCORES: {
    title: 'constants.widgetsDescriptions.tableReviewsWithScores.title',
    description: 'constants.widgetsDescriptions.tableReviewsWithScores.description'
  }
};

export const REVIEW_CALL_STATUSES = {
  ALL: {
    value: '',
    title: 'constants.reviewCallStatuses.all'
  },
  CHECKED: {
    value: true,
    title: 'constants.reviewCallStatuses.checked'
  },
  UNCHECKED: {
    value: false,
    title: 'constants.reviewCallStatuses.unchecked'
  }
};

export const WEEKEND_VIEW_STATUSES = {
  VIEW: {
    value: true,
    title: 'constants.weekendViewStatuses.view'
  },
  HIDE: {
    value: false,
    title: 'constants.weekendViewStatuses.hide'
  }
};

export const CALLS_DIRECTIONS = {
  ALL: {
    value: '',
    title: 'constants.callsDirections.all'
  },
  INCOMING: {
    value: 'incoming',
    title: 'constants.callsDirections.incoming'
  },
  OUTCOMING: {
    value: 'outcoming',
    title: 'constants.callsDirections.outcoming'
  }
};

export const COMMUNICATION_DIRECTIONS = {
  INCOMING: {
    value: 'incoming',
    title: 'constants.communicationDirections.incoming'
  },
  OUTCOMING: {
    value: 'outcoming',
    title: 'constants.communicationDirections.outcoming'
  }
};

export const LOCALES = {
  RU: {
    value: 'ru',
    title: 'constants.locales.ru'
  },
  EN: {
    value: 'en',
    title: 'constants.locales.en'
  },
  ES: {
    value: 'es',
    title: 'constants.locales.es'
  },
  IT: {
    value: 'it',
    title: 'constants.locales.it'
  }
};

export const SESSIONS_DIRECTIONS = {
  PENDING: {
    value: 'pending',
    title: 'constants.sessionsDirections.pending'
  },
  DONE: {
    value: 'done',
    title: 'constants.sessionsDirections.done'
  }
};

export const REVIEW_STATUSES = {
  ALL: {
    value: 'all',
    title: 'constants.reviewStatuses.all'
  },
  REVIEWED: {
    value: true,
    title: 'constants.reviewStatuses.reviewed'
  },
  NOT_REVIEWED: {
    value: false,
    title: 'constants.reviewStatuses.notReviewed'
  }
};

export const RATING_METHODS = {
  SIMPLE: {
    value: 'simple',
    title: 'constants.ratingMethods.simple'
  },
  WEIGHTED: {
    value: 'weighted',
    title: 'constants.ratingMethods.weighted'
  }
};

export const RATING_CALCULATIONS = {
  SUM: {
    value: 'sum',
    title: 'constants.ratingCalculations.sum'
  },
  AVERAGE: {
    value: 'average',
    title: 'constants.ratingCalculations.average'
  }
};

export const RATING_MODES = {
  percentage: {
    value: 'percentage',
    title: 'constants.ratingModes.percentage'
  },
  numbers: {
    value: 'numbers',
    title: 'constants.ratingModes.numbers'
  }
};

export const AUTOFAIL_ENABLED = {
  TRUE: {
    value: true,
    title: 'constants.autofailEnabled.true'
  },
  FALSE: {
    value: false,
    title: 'constants.autofailEnabled.false'
  }
};

export const ONLY_POSITIVE_SCORES = {
  TRUE: {
    value: true,
    title: 'constants.autofailEnabled.true'
  },
  FALSE: {
    value: false,
    title: 'constants.autofailEnabled.false'
  }
};

export const CLIENT_INTERACTION_TYPES = {
  PHONE_CALL: {
    value: 'phone_call',
    title: 'constants.clientInteractionTypes.phone_call'
  },
  CHAT: {
    value: 'chat',
    title: 'constants.clientInteractionTypes.chat'
  },
  TICKET: {
    value: 'ticket',
    title: 'constants.clientInteractionTypes.ticket'
  },
  EMAIL: {
    value: 'email',
    title: 'constants.clientInteractionTypes.email'
  },
  OTHER: {
    value: 'other',
    title: 'constants.clientInteractionTypes.other'
  }
};

export const CLIENT_INTERACTION_DIRECTIONS = {
  ALL: {
    value: 'all',
    title: 'constants.clientInteractionDirections.all'
  },
  INCOMING: {
    value: 'incoming',
    title: 'constants.clientInteractionDirections.incoming'
  },
  OUTCOMING: {
    value: 'outcoming',
    title: 'constants.clientInteractionDirections.outcoming'
  }
};

export const FLAGS_COLORS = {
  RED: {
    value: 'var(--red_4)',
    title: 'constants.flagsColors.red'
  },
  YELLOW: {
    value: 'var(--orange_8)',
    title: 'constants.flagsColors.yellow'
  },
  GREEN: {
    value: 'var(--green_4)',
    title: 'constants.flagsColors.green'
  },
  WITHOUT_FLAG: {
    value: 'var(--gray_8)',
    title: 'constants.flagsColors.withoutFlag'
  }
};

export const COLUMN_TYPES = {
  communicationType: {
    value: 'communicationType',
    icon: UnorderedListOutlined,
    name: 'constants.columnTypes.communicationType.title',
    width: 80
  },
  clientPhoneNumber: {
    value: 'clientPhoneNumber',
    icon: PhoneOutlined,
    name: 'constants.columnTypes.clientPhoneNumber.title',
    width: 200
  },
  startedAt: {
    value: 'startedAt',
    icon: CalendarOutlined,
    name: 'constants.columnTypes.startedAt.title',
    columnName: 'constants.columnTypes.startedAt.columnName',
    width: 160
  },
  nps: {
    value: 'nps',
    icon: StarOutlined,
    name: 'constants.columnTypes.nps.title',
    width: 100
  },
  direction: {
    value: 'direction',
    icon: RetweetOutlined,
    name: 'constants.columnTypes.direction.title',
    width: 150
  },
  text_conversation_response_time_in_seconds: {
    value: 'text_conversation_response_time_in_seconds',
    icon: ClockCircleOutlined,
    name: 'constants.columnTypes.text_conversation_response_time_in_seconds.title',
    width: 200
  },
  email: {
    value: 'email',
    icon: MailOutlined,
    name: 'constants.columnTypes.email.title',
    width: 150
  },
  clientId: {
    value: 'clientId',
    icon: IdcardOutlined,
    name: 'constants.columnTypes.clientId.title',
    width: 200
  },
  chatId: {
    value: 'chatId',
    icon: IdcardOutlined,
    name: 'constants.columnTypes.chatId.title',
    width: 200
  },
  ticketId: {
    value: 'ticketId',
    icon: IdcardOutlined,
    name: 'constants.columnTypes.ticketId.title',
    width: 150
  },
  partsCount: {
    value: 'partsCount',
    icon: MessageOutlined,
    name: 'constants.columnTypes.partsCount.title',
    columnName: 'constants.columnTypes.partsCount.columnName',
    width: 200
  },
  duration: {
    value: 'duration',
    icon: ClockCircleOutlined,
    name: 'constants.columnTypes.duration.title',
    columnName: 'constants.columnTypes.duration.columnName',
    width: 120
  },
  text_conversation_ended_at: {
    value: 'text_conversation_ended_at',
    icon: CalendarOutlined,
    name: 'constants.columnTypes.text_conversation_ended_at.title',
    width: 230
  },
  unitId: {
    value: 'unitId',
    icon: TeamOutlined,
    name: 'constants.columnTypes.unitId.title',
    width: 150
  },
  operatorId: {
    value: 'operatorId',
    icon: UserOutlined,
    name: 'constants.columnTypes.operatorId.title',
    width: 200
  },
  isReviewed: {
    value: 'isReviewed',
    icon: CheckCircleOutlined,
    name: 'constants.columnTypes.isReviewed.title',
    width: 100
  },
  score: {
    value: 'score',
    icon: BarChartOutlined,
    name: 'constants.columnTypes.score.title',
    columnName: 'constants.columnTypes.score.columnName',
    width: 110
  },
  checklistDefinitionName: {
    value: 'checklistDefinitionName',
    icon: FileDoneOutlined,
    name: 'constants.columnTypes.checklistDefinitionName.title',
    width: 200
  },
  reviewReviewerId: {
    value: 'reviewReviewerId',
    icon: UserOutlined,
    name: 'constants.columnTypes.reviewReviewerId.title',
    width: 240
  },
  createdAt: {
    value: 'createdAt',
    icon: ClockCircleOutlined,
    name: 'constants.columnTypes.createdAt.title',
    width: 170
  },
  updatedAt: {
    value: 'updatedAt',
    icon: ClockCircleOutlined,
    name: 'constants.columnTypes.updatedAt.title',
    width: 170
  },
  reviewCreatedAt: {
    value: 'reviewCreatedAt',
    icon: CalendarOutlined,
    name: 'constants.columnTypes.reviewCreatedAt.title',
    width: 150
  },
  reviewComments: {
    value: 'reviewComments',
    icon: FlagOutlined,
    name: 'constants.columnTypes.reviewComments.title',
    width: 220
  },
  taskTaskDefinitions: {
    value: 'taskTaskDefinitions',
    icon: TagsOutlined,
    name: 'constants.columnTypes.taskTaskDefinitions.title',
    width: 200
  },
  status: {
    value: 'status',
    icon: CheckCircleOutlined,
    name: 'constants.columnTypes.status.title',
    width: 200
  },
  // reviewTasksCount: {
  //   value: 'reviewTasksCount',
  // name: 'constants.columnTypes.reviewTasksCount.title'
  // },
  // taskPriority: {
  //   value: 'taskPriority',
  // name: 'constants.columnTypes.taskPriority.title'
  // },
  // taskStatus: {
  //   value: 'taskStatus',
  // name: 'constants.columnTypes.taskStatus.title'
  // },
  // taskAssigneeId: {
  //   value: 'taskAssigneeId',
  // name: 'constants.columnTypes.taskAssigneeId.title'
  // }
  crmEntityId: {
    value: '',
    icon: () => <Icon icon={CaseIcon} style={{ width: '1em', height: '1em' }} />,
    name: 'constants.columnTypes.crmEntityId.title',
    width: 200
  }
};

export const BOOL_VALUES = {
  TRUE: {
    value: true,
    title: 'constants.boolValues.true'
  },
  FALSE: {
    value: false,
    title: 'constants.boolValues.false'
  }
};

export const CUSTOM_FIELDS_TYPES = {
  ENUM: 'enum',
  STRING: 'string',
  NUMBER: 'number',
  STRING_ARRAY: 'string_array',
  STRING_URL: 'string_url',
  NUMBER_ARRAY: 'number_array',
  DATETIME: 'datetime',
  SELECT: 'select',
  BOOLEAN: 'boolean',
  DURATION: 'duration'
};
export const USER_CUSTOM_FIELDS_TYPES = {
  STRING: 'string',
  BOOLEAN: 'boolean'
};

export const WIDGET_VIEW_MODES = {
  OPERATORS: {
    value: 'operators',
    title: 'constants.widgetViewModes.operators'
  },
  REVIEWERS: {
    value: 'reviewers',
    title: 'constants.widgetViewModes.reviewers'
  },
  UNITS: {
    value: 'units',
    title: 'constants.widgetViewModes.units'
  },
  QUESTIONS: {
    value: 'questions',
    title: 'constants.widgetViewModes.questions'
  },
  GROUPS: {
    value: 'groups',
    title: 'constants.widgetViewModes.groups'
  },
  TABLE: {
    value: 'table',
    title: 'constants.widgetViewModes.table'
  },
  CHART: {
    value: 'chart',
    title: 'constants.widgetViewModes.chart'
  },
  SCORE: {
    value: 'score',
    title: 'constants.widgetViewModes.score'
  },
  PERCENTAGE: {
    value: 'percentage',
    title: 'constants.widgetViewModes.percentage'
  }
};

export const DATES_VIEW_FORMAT = {
  DAY: {
    value: 'day',
    title: 'constants.datesViewFormat.day'
  },
  WEEK: {
    value: 'week',
    title: 'constants.datesViewFormat.week'
  },
  MONTH: {
    value: 'month',
    title: 'constants.datesViewFormat.month'
  },
  YEAR: {
    value: 'year',
    title: 'constants.datesViewFormat.year'
  }
};

export const CONVERT_PERIOD_STRING = {
  day: 'days',
  week: 'weeks',
  month: 'months',
  year: 'years'
};

export const HIDDEN_NAME_PLACEHOLDER = 'constants.hiddenNamePlaceholder';

export const WORK_PLAN_TASK_CONFIGURATIONS_STATUSES = {
  draft: {
    value: 'draft',
    title: 'constants.workPlanTaskConfigurationsStatuses.draft',
    color: 'var(--black_8)'
  },
  pending: {
    value: 'pending',
    title: 'constants.workPlanTaskConfigurationsStatuses.pending',
    color: 'var(--orange_primary)'
  },
  in_progress: {
    value: 'in_progress',
    title: 'constants.workPlanTaskConfigurationsStatuses.inProgress',
    color: 'var(--green_primary)'
  },
  completed: {
    value: 'completed',
    title: 'constants.workPlanTaskConfigurationsStatuses.completed',
    color: 'var(--red_primary)'
  },
  stopped: {
    value: 'stopped',
    title: 'constants.workPlanTaskConfigurationsStatuses.stopped',
    color: 'var(--red_primary)'
  }
};

export const PERIOD_REPEAT = {
  day: { title: 'constants.periodRepeat.day', value: 'day' },
  week: { title: 'constants.periodRepeat.week', value: 'week' },
  month: { title: 'constants.periodRepeat.month', value: 'month' },
  never: { title: 'constants.periodRepeat.never', value: 'never' }
};

export const TIME_UNIT = {
  day: { title: 'constants.timeUnit.day', value: 'day' },
  week: { title: 'constants.timeUnit.week', value: 'week' },
  month: { title: 'constants.timeUnit.month', value: 'month' }
};
export const MAX_COUNT_WIDGET_CLIENT_INTERACTIONS = {
  SLOW_SORT_COUNT: 300,
  MAX_CLIENT_INTERACTIONS_COUNT: 1000
};

export const APPEALS_STATUSES = {
  pending: {
    value: 'pending',
    title: 'constants.appealsStatuses.pending',
    color: 'var(--black_6)'
  },
  in_progress: {
    value: 'in_progress',
    title: 'constants.appealsStatuses.inProgress',
    color: 'var(--green_primary)'
  },
  needs_information: {
    value: 'needs_information',
    title: 'constants.appealsStatuses.needsInformation',
    color: 'var(--lilac_primary)'
  },

  accepted: {
    value: 'accepted',
    title: 'constants.appealsStatuses.accepted',
    color: 'var(--green_primary)'
  },
  rejected: {
    value: 'rejected',
    title: 'constants.appealsStatuses.rejected',
    color: 'var(--red_primary)'
  },
  without_answer: {
    value: 'without_answer',
    title: 'constants.appealsStatuses.withoutAnswer',
    color: 'var(--black_primary)'
  }
};

export const APPEAL_PERSON_FILTER_VALUES = {
  assigned_to: {
    value: 'assigned_to',
    title: 'constants.appealPersonFilterValues.assignedTo'
  },
  watcher: {
    value: 'watcher',
    title: 'constants.appealPersonFilterValues.watcher'
  },
  appellant: {
    value: 'appellant',
    title: 'constants.appealPersonFilterValues.appellant'
  }
};

export const APPELLATION_ACTIONS_TYPES = {
  APPELLATION_CREATED: 'appellation_created',
  QUESTION_OBJECTION_APPROVED: 'question_objection_approved',
  QUESTION_OBJECTION_REJECTED: 'question_objection_rejected',
  QUESTION_OBJECTION_ANSWER_CHANGED: 'question_objection_answer_changed',
  GENERAL_APPELLATION_COMMENT_ADDED: 'general_appellation_comment_added',
  APPELLANT_COMMENT_REPLY_ADDED: 'appellant_comment_reply_added',
  WATCHER_ADDED: 'watcher_added',
  WATCHER_REMOVED: 'watcher_removed',
  STATUS_CHANGED: 'status_changed'
};

export const COMMUNICATIONS_DISTRIBUTION_METHODS = {
  COMMUNICATIONS_NUMBER_BY_EACH_OPERATOR: {
    value: 'communications_number_by_each_operator',
    title:
      'workPlanTaskConfigurationEditor.schedule.distributionSettings.communicationsNumberByEachOperator'
  },
  COMMUNICATIONS_PERCENTAGE_BY_EACH_OPERATOR: {
    value: 'communications_percentage_by_each_operator',
    title:
      'workPlanTaskConfigurationEditor.schedule.distributionSettings.communicationsPercentageByEachOperator'
  },
  COMMUNICATIONS_NUMBER_BY_OPERATORS: {
    value: 'communications_number_by_operators',
    title:
      'workPlanTaskConfigurationEditor.schedule.distributionSettings.communicationsNumberByOperators'
  }
};

export const REVIEWERS_COMMUNICATIONS_DISTRIBUTION_METHODS = {
  MANUAL: { value: 'manual', title: 'workPlanTaskConfigurationEditor.distribution.manual' },
  AUTO: { value: 'auto', title: 'workPlanTaskConfigurationEditor.distribution.auto' }
};

export const AUTO_FAIL_TYPES = {
  HISTORY_REPORT: 'autofails_history_report',
  CRITICAL_ERRORS_BY_OPERATORS: 'autofail_counts_by_operators',
  CRITICAL_ERRORS_BY_UNITS: 'autofail_counts_by_units'
};

export const LIMIT_MODAL_TEXT = {
  usersCountLimit: {
    text: 'organizationStructure.tabs.employees.limitCasesModal.usersCountLimit.text',
    description: 'organizationStructure.tabs.employees.limitCasesModal.usersCountLimit.description'
  },
  licenceCountLimit: {
    text: 'organizationStructure.tabs.employees.limitCasesModal.licenceCountLimit.text',
    description_start:
      'organizationStructure.tabs.employees.limitCasesModal.licenceCountLimit.description_start',
    description_end:
      'organizationStructure.tabs.employees.limitCasesModal.licenceCountLimit.description_end'
  }
};

export const LIMIT_MODAL_TYPES = {
  usersCountLimit: 'usersCountLimit',
  licenceCountLimit: 'licenceCountLimit'
};

export const MAX_USERS_COUNT = 1500;
export const CRM_ENTITY_CUSTOM_FIELDS_COLUMN_COUNT = 3;

export const DATASET_STATUSSES = {
  DONE: {
    value: 'done',
    title: 'constants.datasetStatuses.done'
  },
  IN_PROCESS: {
    value: 'in_process',
    title: 'constants.datasetStatuses.process'
  },
  ERROR: {
    value: 'error',
    title: 'constants.datasetStatuses.error'
  }
};
