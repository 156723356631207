import React, { createContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { concat, get, isEmpty, isEqual, isString } from 'lodash';
import { loadDashboards } from 'redux/ui/dashboard/operations';
import { toogleVisibleDrawer } from 'redux/ui/dashboard/reducer';
import { COLOR_PALETTE_7 } from 'core/utils/colors';
import { useTranslation } from 'react-i18next';
import { SRow, SCol } from 'components/Standard';
import { getUnitsByIds } from 'redux/selectors/units';
import { getUsersByIds } from 'redux/selectors/users';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';

// * small widgets
import ScoreWidget from './SmallWidgets/ScoreWidget';
import TimeWidget from './SmallWidgets/TimeWidget';

// * medium widgets
import LabelsCountByUnits from './MediumWidgets/LabelsCountByUnits/LabelsCountByUnits';
import LabelsCountByOperators from './MediumWidgets/LabelsCountByOperators/LabelsCountByOperators';
import RatingFlagsCount from './MediumWidgets/RatingFlagsCount/RatingFlagsCount';
import ReviewsCountByReviewers from './MediumWidgets/ReviewsCountByReviewers/ReviewsCountByReviewers';
import RatingFlagsCountByUnits from './MediumWidgets/RatingFlagsCountByUnits/RatingFlagsCountByUnits';
import PhoneCallsCountByOperators from './MediumWidgets/PhoneCallsCountByOperators/PhoneCallsCountByOperators';
import PhoneCallsAverageDurationByUnits from './MediumWidgets/PhoneCallsAverageDurationByUnits/PhoneCallsAverageDurationByUnits';
import PhoneCallsAverageDurationByOperators from './MediumWidgets/PhoneCallsAverageDurationByOperators/PhoneCallsAverageDurationByOperators';
import RatingFlagsCountByOperators from './MediumWidgets/RatingFlagsCountByOperators/RatingFlagsCountByOperators';
import ChecklistDefinitionQuestionsAverageScores from './MediumWidgets/ChecklistDefinitionQuestionsAverageScores/ChecklistDefinitionQuestionsAverageScores';
import ChecklistDefinitionAverageScoreByUnits from './MediumWidgets/ChecklistDefinitionAverageScoreByUnits/ChecklistDefinitionAverageScoreByUnits';
import ChecklistDefinitionAverageScoreByOperators from './MediumWidgets/ChecklistDefinitionAverageScoreByOperators/ChecklistDefinitionAverageScoreByOperators';
import ChecklistDefinitionAverageScoreByChecklistDefinitions from './MediumWidgets/ChecklistDefinitionAverageScoreByChecklistDefinitions/ChecklistDefinitionAverageScoreByChecklistDefinitions';
import ChecklistDefinitionQuestionGroupsAverageScores from './MediumWidgets/ChecklistDefinitionQuestionGroupsAverageScores/ChecklistDefinitionQuestionGroupsAverageScores';
import ChecklistDefinitionQuestionsAverageScoresByQuestionGroups from './MediumWidgets/ChecklistDefinitionQuestionsAverageScoresByQuestionGroups/ChecklistDefinitionQuestionsAverageScoresByQuestionGroups';
import PhoneCallsCountByUnits from './MediumWidgets/PhoneCallsCountByUnits/PhoneCallsCountByUnits';
import ReviewsCountByReviewersHistory from './LargeWidgets/ReviewsCountByReviewersHistory/ReviewsCountByReviewersHistory';
import ChecklistDefinitionAverageScoreByOperatorsHistory from './LargeWidgets/ChecklistDefinitionAverageScoreByOperatorsHistory/ChecklistDefinitionAverageScoreByOperatorsHistory';
import ChecklistDefinitionAverageScoreByUnitsHistory from './LargeWidgets/ChecklistDefinitionAverageScroeByUnitsHistory/ChecklistDefinitionAverageScroeByUnitsHistory';
import AverageScoreByQuestionsHistory from './LargeWidgets/AverageScoreByQuestionsHistory/AverageScoreByQuestionsHistory';
import AverageScoreByQuestionGroupsHistory from './LargeWidgets/AverageScoreByQuestionGroupsHistory/AverageScoreByQuestionGroupsHistory';
import PhoneCallsAverageDurationByOperatorsHistory from './LargeWidgets/PhoneCallsAverageDurationByOperatorsHistory/PhoneCallsAverageDurationByOperatorsHistory';
import PhoneCallsAverageDurationByUnitsHistory from './LargeWidgets/PhoneCallsAverageDurationByUnitsHistory/PhoneCallsAverageDurationByUnitsHistory';
import ClientInteractionsCountByOperatorsHistory from './LargeWidgets/ClientInteractionsCountByOperatorsHistory/ClientInteractionsCountByOperatorsHistory';
import ClientInteractionsCountByUnitsHistory from './LargeWidgets/ClientInteractionsCountByUnitsHistory/ClientInteractionsCountByUnitsHistory';

// * extra_large widgets
import TableReviewsCountByOperators from './ExtraLargeWidgets/TableReviewsCountByOperators/TableReviewsCountByOperators';
import TableReviewsCountByOperatorsWithKpi from './ExtraLargeWidgets/TableReviewsCountByOperatorsWithKpi/TableReviewsCountByOperatorsWithKpi';
import TableChecklistItemsByOperators from './ExtraLargeWidgets/TableChecklistItemsByOperators/TableChecklistItemsByOperators';
import TableChecklistItemsHistory from './ExtraLargeWidgets/TableChecklistItemsHistory/TableChecklistItemsHistory';
import FlagsAndComments from './ExtraLargeWidgets/FlagsAndComments/FlagsAndComments';
import TableChecklistsScoresByOperators from './ExtraLargeWidgets/TableChecklistsScoresByOperators/TableChecklistsScoresByOperators';
import TableReviewsCountByReviewers from './ExtraLargeWidgets/TableReviewsCountByReviewers/TableReviewsCountByReviewers';
import TableReviewsWithScores from './ExtraLargeWidgets/TableReviewsWithScores/TableReviewsWithScores';

// * empty entities
import EmptyWidget from './Components/EmptyWidget';
import EmptyDashboard from './Components/EmptyDashboard';
import EmptyDashboardRows from './Components/EmptyDashboardRows';
import LoadingDashboard from './Components/LoadingDashboard';

import DashboardSettingsDrawer from './DashboardSettingsDrawer/DashboardSettingsDrawer';
import { TableReviewsWithScoresProvider } from './ExtraLargeWidgets/TableReviewsWithScores/hooks';
import { hasConflictFilters } from './utils';
import DashboardExportDrawer from './DashboardExportDrawer/DashboardExportDrawer';

export const ContextExportDashboards = createContext();
const DashboardPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDashboards());
  }, []);

  const loading = useSelector(state => state.dashboardAnalytics.loading, isEqual);
  const activeDashboardId = useSelector(state => state.uiDashboard.activeDashboardId, isEqual);
  const dashboardRowsByIds = useSelector(state => state.dashboardRowsResource.byIds, isEqual);
  const dashboardWidgetsByIds = useSelector(state => state.dashboardWidgetsResource.byIds, isEqual);
  const { loadingWidgetId } = useSelector(state => state.dashboardAnalytics, isEqual);
  const dashboardsByIds = useSelector(state => state.dashboardsResource.byIds, isEqual);
  const units = useSelector(state => getUnitsByIds(state));
  const users = useSelector(state => getUsersByIds(state));
  const checklists = useSelector(state => getChecklistsDefinitionsByIds(state));

  const arrayOfUsers = Object.keys(users);
  const arrayOfUnits = Object.keys(units);
  const arrayOfChecklists = Object.keys(checklists);

  const activeDashboardRowsIds = get(dashboardsByIds, [activeDashboardId, 'rowsIds'], []).filter(
    row => dashboardRowsByIds[row]
  );

  const renderWidget = widgetData => {
    const widgetOperatorsIds = get(widgetData.filters, 'operatorsIds', []);
    const widgetUnitsIds = get(widgetData.filters, 'unitsIds', []);
    const widgetChecklistsIdsFromFilters = get(widgetData.filters, 'checklistDefinitionsIds', []);
    const widgetReviewersIdsFromFilters = get(widgetData.filters, 'reviewersIds', []);
    const widgetChecklistsIds = isString(widgetChecklistsIdsFromFilters)
      ? concat([], widgetChecklistsIdsFromFilters)
      : [...widgetChecklistsIdsFromFilters];
    const isWarningWidget =
      hasConflictFilters({ availableReduxFilters: arrayOfUnits, currentFilters: widgetUnitsIds }) ||
      hasConflictFilters({
        availableReduxFilters: arrayOfUsers,
        currentFilters: widgetOperatorsIds
      }) ||
      hasConflictFilters({
        availableReduxFilters: arrayOfUsers,
        currentFilters: widgetReviewersIdsFromFilters
      }) ||
      hasConflictFilters({
        availableReduxFilters: arrayOfChecklists,
        currentFilters: widgetChecklistsIds
      });

    const emptyWidgetRenderer = data => (
      <EmptyWidget
        key={data.id}
        widgetData={data}
        loadingWidgetId={loadingWidgetId}
        isWarningWidget={isWarningWidget}
      />
    );

    const widgetTypeToComponentRenderer = {
      // ! small widgets
      checklist_definition_average_score: data => (
        <ScoreWidget
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      phone_calls_count: data => (
        <ScoreWidget
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      phone_calls_average_duration: data => (
        <TimeWidget
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      total_phone_calls_duration: data => (
        <TimeWidget
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      reviewed_client_interactions_duration: data => (
        <TimeWidget
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      reviewed_client_interactions_percentage: data => (
        <ScoreWidget
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      // ! medium widgets
      checklist_definition_average_score_by_units: data => (
        <ChecklistDefinitionAverageScoreByUnits
          widgetData={data}
          key={data.id}
          colors={['var(--blue_6)']}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      checklist_definition_average_score_by_operators: data => (
        <ChecklistDefinitionAverageScoreByOperators
          widgetData={data}
          key={data.id}
          colors={['var(--green_6)']}
          loadingWidgetId={loadingWidgetId}
          maxValue
          isWarningWidget={isWarningWidget}
        />
      ),

      checklist_definition_average_score_by_checklist_definitions: data => (
        <ChecklistDefinitionAverageScoreByChecklistDefinitions
          widgetData={data}
          key={data.id}
          colors="var(--orange_6)"
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      phone_calls_count_by_units: data => (
        <PhoneCallsCountByUnits
          widgetData={data}
          key={data.id}
          colors={['var(--blue_6)']}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      phone_calls_count_by_operators: data => (
        <PhoneCallsCountByOperators
          widgetData={data}
          key={data.id}
          colors={['var(--green_6)']}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      reviews_count_by_reviewers: data => (
        <ReviewsCountByReviewers
          widgetData={data}
          key={data.id}
          colors="var(--green_6)"
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      rating_flags_count: data => (
        <RatingFlagsCount
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      rating_flags_count_by_units: data => (
        <RatingFlagsCountByUnits
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      rating_flags_count_by_operators: data => (
        <RatingFlagsCountByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      labels_count_by_operators: data => (
        <LabelsCountByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      labels_count_by_units: data => (
        <LabelsCountByUnits
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      checklist_definition_questions_average_scores: data => (
        <ChecklistDefinitionQuestionsAverageScores
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors="var(--orange_6)"
          isWarningWidget={isWarningWidget}
        />
      ),

      checklist_definition_questions_average_scores_by_question_groups: data => (
        <ChecklistDefinitionQuestionsAverageScoresByQuestionGroups
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      checklist_definition_question_groups_average_scores: data => (
        <ChecklistDefinitionQuestionGroupsAverageScores
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors="var(--orange_6)"
          isWarningWidget={isWarningWidget}
        />
      ),

      phone_calls_average_duration_by_units: data => (
        <PhoneCallsAverageDurationByUnits
          widgetData={data}
          key={data.id}
          colors={['var(--blue_6)']}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      phone_calls_average_duration_by_operators: data => (
        <PhoneCallsAverageDurationByOperators
          widgetData={data}
          key={data.id}
          colors={['var(--green_6)']}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),

      // ! large widgets
      reviews_count_by_reviewers_history: data => (
        <ReviewsCountByReviewersHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      checklist_definition_average_score_by_operators_history: data => (
        <ChecklistDefinitionAverageScoreByOperatorsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      checklist_definition_average_score_by_units_history: data => (
        <ChecklistDefinitionAverageScoreByUnitsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      average_score_by_questions_history: data => (
        <AverageScoreByQuestionsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      average_score_by_question_groups_history: data => (
        <AverageScoreByQuestionGroupsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      phone_calls_average_duration_by_operators_history: data => (
        <PhoneCallsAverageDurationByOperatorsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      phone_calls_average_duration_by_units_history: data => (
        <PhoneCallsAverageDurationByUnitsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      client_interactions_count_by_operators_history: data => (
        <ClientInteractionsCountByOperatorsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      client_interactions_count_by_units_history: data => (
        <ClientInteractionsCountByUnitsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_7)}
          isWarningWidget={isWarningWidget}
        />
      ),

      // ! extra large widgets
      table_reviews_count_by_operators: data => (
        <TableReviewsCountByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      table_reviews_count_by_operators_with_kpi: data => (
        <TableReviewsCountByOperatorsWithKpi
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      table_checklist_items_by_operators: data => (
        <TableChecklistItemsByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      table_checklist_items_history: data => (
        <TableChecklistItemsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      flags_and_comments: data => (
        <FlagsAndComments
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      table_checklists_scores_by_operators: data => (
        <TableChecklistsScoresByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      table_reviews_count_by_reviewers: data => (
        <TableReviewsCountByReviewers
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ),
      table_reviews_with_scores: data => (
        <TableReviewsWithScoresProvider key={data.id}>
          <TableReviewsWithScores
            widgetData={data}
            loadingWidgetId={loadingWidgetId}
            isWarningWidget={isWarningWidget}
          />
        </TableReviewsWithScoresProvider>
      )
    };

    const rendererFunction = get(
      widgetTypeToComponentRenderer,
      widgetData.type,
      emptyWidgetRenderer
    );
    return rendererFunction(widgetData);
  };

  const dashboardStatusToRenderComponent = ({
    loading,
    dashboardsByIds,
    activeDashboardRowsIds
  }) => {
    if (loading) return <LoadingDashboard />;
    if (isEmpty(dashboardsByIds) && isEmpty(activeDashboardRowsIds))
      return <EmptyDashboard loading={loading} />;
    if (isEmpty(activeDashboardRowsIds) && !isEmpty(dashboardsByIds))
      return (
        <EmptyDashboardRows
          loading={loading || !dashboardsByIds[activeDashboardId]}
          toggleVisibleDrawer={toogleVisibleDrawer}
        />
      );
    return activeDashboardRowsIds.map(rowId => (
      <Row gutter={[16, 16]} key={rowId}>
        {get(dashboardRowsByIds, [rowId, 'widgetsIds'], []).map(widgetId =>
          renderWidget(dashboardWidgetsByIds[widgetId])
        )}
      </Row>
    ));
  };

  let exportWidgetDataArray = [];
  const [isExportWidgetData, setIsExportWidgetData] = useState(true);
  const addElementExportWidget = body => {
    exportWidgetDataArray.push(body);
  };

  const clearArrayExportWidget = () => {
    exportWidgetDataArray = [];
  };
  const handleIsExportWidget = value => {
    setIsExportWidgetData(value || !isExportWidgetData);
  };
  return (
    <ContextExportDashboards.Provider
      value={{
        exportWidgetDataArray,
        addElementExportWidget,
        clearArrayExportWidget,
        isExportWidgetData,
        handleIsExportWidget
      }}
    >
      <SRow>
        <SCol span={24} padding="16px">
          <Helmet>
            <title>{t('pagesMeta.dashboardPage.title')}</title>
          </Helmet>
          {dashboardStatusToRenderComponent({ loading, dashboardsByIds, activeDashboardRowsIds })}
          {!isEmpty(dashboardsByIds) && <DashboardSettingsDrawer />}
          {!isEmpty(dashboardsByIds) && <DashboardExportDrawer dashboardsByIds={dashboardsByIds} />}
        </SCol>
      </SRow>
    </ContextExportDashboards.Provider>
  );
};

export default DashboardPage;
