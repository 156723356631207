import { Col, Row, Tooltip, Typography } from 'antd';
import { INTEGRATIONS } from 'core/utils/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { mangoInstallEndpoint } from 'core/api';
import { useSelector, useDispatch } from 'react-redux';
import { createAmoIntegration } from 'redux/entities/amoIntegration/operations';
import { createBinotelIntegration } from 'redux/entities/binotelIntegration/operations';
import { createBitrixIntegration } from 'redux/entities/bitrixIntegration/operations';
import { createSipuniIntegration } from 'redux/entities/sipuniIntegration/operations';
import { createMangoIntegration } from 'redux/entities/mangoIntegration/operations';
import { createUsedeskIntegration } from 'redux/entities/usedeskIntegration/operations';
import { createZendeskIntegration } from 'redux/entities/zendeskIntegration/operations';
import { createInfinityIntegration } from 'redux/entities/infinityIntegration/operations';
import { createHelpDeskEddyIntegration } from 'redux/entities/helpDeskEddyIntegration/operations';
import { createBrightPatternIntegration } from 'redux/entities/brightPatternIntegration/operations';
import { createCustomIntegration } from 'redux/entities/customIntegration/operations';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import Icon from 'components/Icon';
import { BookOpen } from 'react-feather';
import SText from 'components/Standard/SText';
import { getManualLinkInfo } from 'pages/UserPage/Integrations/utils';
import { createUisIntegration } from 'redux/entities/uisIntegration/operations';
import NameAndType from './IntegrationSettingsModules/NameAndType';
import { createNaumenIntegration } from 'redux/entities/naumenIntegration/operations';
import { createEdnaIntegration } from 'redux/entities/ednaIntegration/operations';

const integrationTypeToHandler = {
  [INTEGRATIONS.amocrm.type]: createAmoIntegration,
  [INTEGRATIONS.bitrix_crm.type]: createBitrixIntegration,
  [INTEGRATIONS.custom_http_telephony.type]: createCustomIntegration,
  [INTEGRATIONS.custom_http_text.type]: createCustomIntegration,
  [INTEGRATIONS.sipuni.type]: createSipuniIntegration,
  [INTEGRATIONS.mango.type]: createMangoIntegration,
  [INTEGRATIONS.usedesk.type]: createUsedeskIntegration,
  [INTEGRATIONS.binotel.type]: createBinotelIntegration,
  [INTEGRATIONS.zendesk.type]: createZendeskIntegration,
  [INTEGRATIONS.help_desk_eddy.type]: createHelpDeskEddyIntegration,
  [INTEGRATIONS.infinity.type]: createInfinityIntegration,
  [INTEGRATIONS.bright_pattern.type]: createBrightPatternIntegration,
  [INTEGRATIONS.uis.type]: createUisIntegration,
  [INTEGRATIONS.naumen.type]: createNaumenIntegration,
  [INTEGRATIONS.edna.type]: createEdnaIntegration
};

const { Title } = Typography;

const NewIntegrationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [integrationType, setIntegrationType] = useState();
  const organization_id = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  const user_id = useSelector(
    state => state.reduxTokenAuth?.currentUser?.attributes?.id
  );

  const onSetNameAndType = async values => {
    let handlerTypes = ['mango'];
    let integrationId;
    let created_at
    let integration_type
    if (!handlerTypes.includes(values.integrationType)) {
      integrationId = await dispatch(
        integrationTypeToHandler[values.integrationType]({
          ...values,
          url,
          history,
          organization_id
        })
      );
    } else {
      values.endpoint = 'https://app.mango-office.ru/vpbx/';
      values.organization_id = organization_id;
      let requestOptions = {
        method: "POST",
        body: JSON.stringify(values)
      };

      let resp = await fetch(mangoInstallEndpoint, requestOptions);//mangoInstallEndpoint
      resp = await resp.json();
      integrationId = resp?.integration?.data?.id;
      created_at = resp?.integration?.data?.attributes?.created_at;
      integration_type = resp?.integration?.data?.attributes?.integration_type;
    }

    if (values.integrationType === 'mango') {
      if (integrationId) {
        history.push('/user/' + user_id + '/integrations-settings/mango/' + integrationId + '?created_at=' + created_at + '&integration_type=' + integration_type);
        window.location.reload();
      }
    } else {
      if (integrationId) {
        history.push(`${url}/${values.integrationType}/${integrationId}`);
      }
    }
  };

  const { manualTextLink, manualLink } = getManualLinkInfo(integrationType);

  return (
    <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
      <Col span={24}>
        <SRow type="flex" wrap={false} justify="space-between">
          <SCol flex="auto">
            <Title level={4}>{t('integrationsSettingsPage.addIntegration.title')}</Title>
          </SCol>
          <SCol flex="none">
            <Tooltip title={manualTextLink}>
              <a target="_blank" href={manualLink} rel="noreferrer">
                <SRow>
                  <SCol display="flex" alignItems="center">
                    <Icon icon={BookOpen} marginTop="1px" marginRight="6px" size="20px" />
                    <SText ellipsis color="var(--link-color)">
                      {manualTextLink}
                    </SText>
                  </SCol>
                </SRow>
              </a>
            </Tooltip>
          </SCol>
        </SRow>
      </Col>
      <Col span={24}>
        <NameAndType
          onSubmit={onSetNameAndType}
          onChangeIntegrationType={type => setIntegrationType(type)}
        />
      </Col>
    </Row>
  );
};

export default NewIntegrationPage;
