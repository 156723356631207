import React from 'react';
import { Col, Row, Typography, Tooltip } from 'antd';
import { get, isEmpty } from 'lodash';
import ColorZonesBar from 'components/Team/ColorZonesBar';
import DifferenceArrow from 'components/DifferenceArrow';
import RedFlag from 'components/Flags/RedFlag';
import YellowFlag from 'components/Flags/YellowFlag';
import GreenFlag from 'components/Flags/GreenFlag';
import StandardComment from 'components/Flags/StandardComment';
import { beatifyFloat } from 'core/utils/convertFloat';
import { ANALYTICS_WIDGETS_NAMES, RATING_MODE } from 'core/utils/constants';
import { Trans } from 'react-i18next';

const Columns = ({ filters, checklistDefinitionsByIds }) => {
  const { Text } = Typography;

  const selectedChecklistDefinition = get(
    checklistDefinitionsByIds,
    filters.checklistDefinitionsIds
  );

  const { checklistDefinitionsIds } = filters;

  const displayTypeString =
    selectedChecklistDefinition?.ratingMode === RATING_MODE.PERCENTAGE ? (
      '%'
    ) : (
      <Trans i18nKey="teamPage.unitTable.differenceArrowScoreLiteral" />
    );

  const getPrevDiff = (previousPeriod, currentPeriod) => {
    if (!isEmpty(previousPeriod)) {
      return '';
    }
    if (previousPeriod > currentPeriod) {
      const prevDiff = previousPeriod - currentPeriod;
      if (prevDiff < 0.1) return null;
      return `${beatifyFloat(prevDiff, 1)}`;
    }

    if (previousPeriod < currentPeriod) {
      const currDiff = currentPeriod - previousPeriod;
      if (currDiff < 0.1) return null;
      return `${beatifyFloat(currDiff, 1)}`;
    }
    return '';
  };

  return [
    // Отдел
    {
      title: <Trans i18nKey="teamPage.unitTable.columns.unit" />,
      dataIndex: 'unitName',
      key: 'unitName',
      width: 200,
      ellipsis: true,
      render: text => (
        <Tooltip title={text} placement="topLeft">
          <Text>{text}</Text>
        </Tooltip>
      ),
      sorter: (a, b) => a.unitName.localeCompare(b.unitName)
    },
    // Балл
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.scoreInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.score" />
          </Text>
        </Tooltip>
      ),
      dataIndex: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS,
      key: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS,
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          <Col>
            <Text>
              {filters.checklistDefinitionsIds === 'all' || !filters.checklistDefinitionsIds
                ? 'N/A'
                : `${beatifyFloat(text)}${
                    get(selectedChecklistDefinition, 'ratingMode') === RATING_MODE.PERCENTAGE ? '%' : ''
                  }`}
            </Text>
          </Col>
        </Row>
      ),
      sorter: (a, b) =>
        a[ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS] -
        b[ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS]
    },
    // Балл прошлый период
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.scoreInfoPrevious" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.previous" />
          </Text>
        </Tooltip>
      ),
      dataIndex: `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}_previous`,
      key: `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}_previous`,
      align: 'center',
      width: 200,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          {checklistDefinitionsIds === 'all' || !checklistDefinitionsIds ? null : (
            <Col>
              <DifferenceArrow
                previousPeriod={text}
                currentPeriod={record.checklist_definition_average_score_by_operators}
                displayTypeString={displayTypeString}
              />
            </Col>
          )}
        </Row>
      ),
      sorter: (a, b) => {
        const averageScoreA = a?.checklist_definition_average_score_by_operators;
        const averageScorePreviousA = a?.checklist_definition_average_score_by_operators_previous;
        const averageScoreB = b?.checklist_definition_average_score_by_operators;
        const averageScorePreviousB = b?.checklist_definition_average_score_by_operators_previous;
        // Для выполнения сортировки вычисляем значение относительно прошлого периода
        const valueA = getPrevDiff(averageScorePreviousA, averageScoreA);
        const valueB = getPrevDiff(averageScorePreviousB, averageScoreB);
        return valueA - valueB;
      }
    },
    // Проверки
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.reviewsCountInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.reviewsCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
      key: ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
      align: 'center',
      width: 130,
      sorter: (a, b) =>
        a[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS] -
        b[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS]
    },
    // Отлично
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.greenZoneCountInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.greenZoneCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'greenZonePercentage',
      key: 'greenZonePercentage',
      align: 'center',
      width: 120,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          <Col>
            <Text>{`${beatifyFloat(text)}%`}</Text>
          </Col>
        </Row>
      ),
      sorter: (a, b) => a.greenZonePercentage - b.greenZonePercentage
    },
    // Отлично прошлый период
    {
      title: (
        <Tooltip
          title={<Trans i18nKey="teamPage.unitTable.infoIcons.greenZoneCountInfoPrevious" />}
        >
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.previous" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'greenZonePercentagePrevious',
      key: 'greenZonePercentagePrevious',
      align: 'center',
      width: 200,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          <Col>
            <DifferenceArrow
              previousPeriod={text}
              currentPeriod={record.greenZonePercentage}
              displayTypeString="%"
            />
          </Col>
        </Row>
      ),
      sorter: (a, b) => {
        const greenZonePercentageA = a?.greenZonePercentage;
        const greenZonePercentagePreviousA = a?.greenZonePercentagePrevious;
        const greenZonePercentageB = b?.greenZonePercentage;
        const greenZonePercentagePreviousB = b?.greenZonePercentagePrevious;
        // Для выполнения сортировки вычисляем значение относительно прошлого периода
        const valueA = getPrevDiff(greenZonePercentagePreviousA, greenZonePercentageA);
        const valueB = getPrevDiff(greenZonePercentagePreviousB, greenZonePercentageB);
        return valueA - valueB;
      }
    },
    // Все оценки
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.allScoresInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.allScores" />
          </Text>
        </Tooltip>
      ),
      key: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
      dataIndex: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
      render: (text, record) => (
        <ColorZonesBar
          data={text}
          reviewsCount={record[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS]}
        />
      ),
      align: 'center'
    },
    // Без флага
    {
      title: <StandardComment />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: text => (
        <Row gutter={[4, 4]} type="flex" justify="center">
          <Col>
            <StandardComment />
          </Col>
          <Col>
            <Text>{`${get(text, 'without_flag', 0)}`}</Text>
          </Col>
        </Row>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`, 0)
    },
    // Красный флаг
    {
      title: <RedFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: text => (
        <Row gutter={[4, 4]} type="flex" justify="center">
          <Col>
            <RedFlag />
          </Col>
          <Col>
            <Text>{`${get(text, 'red', 0)}`}</Text>
          </Col>
        </Row>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`, 0)
    },
    // Желтый флаг
    {
      title: <YellowFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: text => (
        <Row gutter={[4, 4]} type="flex" justify="center">
          <Col>
            <YellowFlag />
          </Col>
          <Col>
            <Text>{`${get(text, 'yellow', 0)}`}</Text>
          </Col>
        </Row>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`, 0)
    },
    // Зеленый флаг
    {
      title: <GreenFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: text => (
        <Row gutter={[4, 4]} type="flex" justify="center">
          <Col>
            <GreenFlag />
          </Col>
          <Col>
            <Text>{`${get(text, 'green', 0)}`}</Text>
          </Col>
        </Row>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`, 0)
    }
  ];
};

export default Columns;
