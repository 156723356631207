import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useDispatch, useSelector } from 'react-redux';
import { PERMISSIONS, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { get, isEmpty, isEqual } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import WidgetViewChanger from 'components/Dashboard/WidgetViewChanger';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons/IconButton';
import moment from 'moment';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from 'components/Standard/SRow';
import { LargeWidgetCard } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  exportWidgetData,
  widgetInfoTextSetter,
  widgetNameSetter,
  widgetPeriodSetter
} from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';
import { prepareColumns, prepareRows } from './prepareTableData';
import { prepareChartTable, prepareChartData } from './prepareChartData';
import TableView from './Views/TableView';
import ChartView from './Views/ChartView';
import { ContextExportDashboards } from '../../DashboardPage';

const TableReviewsCountByReviewers = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, type, filters, name } = widgetData;
  const selectedUserId = useSelector(
    state => get(state, `uiDashboard.chartsView.widgets.${id}.selectedUserId`, {}),
    isEqual
  );
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const isVisibleReviewerName = useSelector(
    state => getCurrentUserPermissions(state).includes(PERMISSIONS.CAN_SEE_REVIEWER_NAME),
    isEqual
  );

  const [loadingExport, setLoadingExport] = useState(false);
  const { isWeekendView, historyGroupingPeriod } = filters;
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.REVIEWERS.value);
  const viewType = get(filters, 'viewType', WIDGET_VIEW_MODES.TABLE.value);

  const widgetValue = get(widgetsAnalyticsByIds, id, {});
  const metadata = widgetValue?.metadata;
  const timeFrom = metadata?.time_from;
  const timeTo = metadata?.time_to;

  const viewData = {
    reviewsCountByReviewers: get(widgetValue, 'reviews_count_by_reviewers', []),
    reviewsCountByUnits: get(widgetValue, 'reviews_count_by_units', []),
    reviewsCountByReviewersHistory: get(widgetValue, 'reviews_count_by_reviewers_history', []),
    reviewsCountByUnitsHistory: get(widgetValue, 'reviews_count_by_units_history', []),
    reviewedClientInteractionsDurationByReviewersHistory: get(
      widgetValue,
      'reviewed_client_interactions_duration_by_reviewers_history',
      []
    ),
    reviewedClientInteractionsDurationByUnitsHistory: get(
      widgetValue,
      'reviewed_client_interactions_duration_by_units_history',
      []
    )
  };

  const { rows, totalScore } = prepareRows({
    viewData,
    usersByIds,
    unitsByIds,
    viewMode,
    isWeekendView,
    timeFrom,
    timeTo,
    historyGroupingPeriod
  });

  const tableData = {
    tableColumns: prepareColumns({
      viewMode,
      isWeekendView,
      timeFrom,
      timeTo,
      historyGroupingPeriod
    }),
    tableRows: rows
  };

  const { columns: tableColumns, rows: tableRows } = prepareChartTable({
    selectedUserId,
    viewData,
    viewMode,
    isWeekendView,
    timeFrom,
    timeTo,
    historyGroupingPeriod
  });

  const chartData = {
    table: {
      tableColumns,
      tableRows
    },
    chart: prepareChartData({ filters, timeFrom, timeTo, viewData })
  };

  const isEmptyWidgetData = isEmpty(
    viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
      ? viewData.reviewsCountByReviewers
      : viewData.reviewsCountByUnits
  );

  const getNameForExport = ({ item }) => {
    if (item.key === 'footer' || item.key === 'header') return t(item.name);
    return isVisibleReviewerName || viewMode === WIDGET_VIEW_MODES.UNITS.value
      ? item.name
      : t('constants.hiddenNamePlaceholder');
  };

  const body = {
    widgetType: type,
    widgetData: {
      columns: tableData.tableColumns.reduce((acc, item) => {
        if (filters.viewMode === WIDGET_VIEW_MODES.UNITS.value && item.key === 'unitName')
          return acc;

        const data = {
          ...item,
          title: ['name', 'totalScore', 'unitName'].includes(item.key)
            ? t(item?.title?.props?.i18nKey)
            : moment(item.key).format('DD/MM')
        };

        return [...acc, data];
      }, []),
      rows: tableData.tableRows.map(item => ({
        ...item,
        name: getNameForExport({ item })
      }))
    }
  };
  const { addElementExportWidget } = useContext(ContextExportDashboards);
  const prevBodyRef = useRef(null);

  useLayoutEffect(() => {
    if (prevBodyRef.current !== body && (!isEmptyWidgetData && !isWarningWidget)) {
      prevBodyRef.current = body;
      addElementExportWidget({ ...prevBodyRef.current, id });
    }
  }, [body]);
  const onChangeViewType = viewType => {
    return dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: { ...filters, viewType }
      })
    );
  };

  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData || isWarningWidget || totalScore === 0 ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top" wrap={false}>
              <SCol flex="auto" span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type
                  })}
                </TimePeriod>
              </SCol>
              <SCol display="flex" justifyContent="flex-end" flex="none" alignItems="center">
                <WidgetViewChanger value={viewType} onChange={onChangeViewType} />
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
              </SCol>
            </Row>
            {viewType === WIDGET_VIEW_MODES.TABLE.value ? (
              <TableView
                columns={tableData.tableColumns.filter(item => item.key !== 'unitName')}
                rows={tableData.tableRows}
              />
            ) : (
              <ChartView chartData={chartData} widgetId={id} selectedUserId={selectedUserId} />
            )}
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableReviewsCountByReviewers;
