import { Alert, Col, Row, Select } from 'antd';
import SCard from 'components/Standard/SCard';
import React, { useEffect, useState } from 'react';
import { SYNC_DATA_PERIODS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import SButton from 'components/Standard/SButton';

const { Option } = Select;

const SyncPeriod = ({ disabled, defaultValue, onOk, okText, type = null, okProps = {}, syncAtTime = '', integration_id = null }) => {
  const mounted = React.useRef(false);
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const handleOk = async () => {
    setLoading(true);
    await onOk(value);
    mounted.current && setLoading(false);
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <SCard>
      {disabled && (type != 'amocrm' && type !== 'naumen' && type != 'mango' && type !== 'edna') && (
        <Alert
          style={{ marginBottom: '16px' }}
          showIcon
          type="info"
          message={t('integrationsSettingsPage.infoAlert.message')}
          description={t('integrationsSettingsPage.infoAlert.description', { time: syncAtTime })}
        />
      )}
      {disabled && (type == 'amocrm' || type === 'naumen' || type == 'mango' || type === 'edna') && (
        <Alert
          style={{ marginBottom: '16px' }}
          showIcon
          type="info"
          message="Синхронизация в данный момент уже выполняется. Дождитесь завершения или попробуйте обновить страницу."
        />
      )}
      {!disabled && (type == 'amocrm' || type === 'naumen' || type == 'mango' || type === 'edna') && (
        <Alert
          style={{ marginBottom: '16px' }}
          showIcon
          type="info"
          message="Пожалуйста задайте диапазон для синхронизации данных. Обратите внимание, чем больше заданный диапазон, тем больше времени займет синхронизация. Вы всегда сможете увеличить диапазон синхронизируемых данных в настройках интеграции."
        />
      )}
      <Row align="middle" justify="space-between">
        <Col>
          <Select
            size="large"
            style={{ width: '380px' }}
            disabled={disabled}
            onChange={setValue}
            value={value}
            placeholder={t('integrationsSettingsPage.selectSyncPeriod')}
          >
            {Object.values(SYNC_DATA_PERIODS).map(period => (
              <Option value={period.key} key={period.key}>
                {t(period.name)}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <SButton
            width="230px"
            type="primary"
            size="big"
            onClick={handleOk}
            loading={loading}
            disabled={!value || disabled}
            {...okProps}
          >
            {okText}
          </SButton>
        </Col>
      </Row>
    </SCard>
  );
};

export default SyncPeriod;
