import { Col } from 'antd';
import SCard from 'components/Standard/SCard';
import { get, isEqual, isEmpty, orderBy, isNull } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMENTS_FLAGS_COLORS_RELATIONS, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import { deleteReplyComment, updateReplyComment } from 'redux/ui/clientInteractionPage/operations';
import styled from 'styled-components';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { getCommentsFilteredByPermission } from 'redux/selectors/comments';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import CommentInfo from '../Components/CommentInfo';
import CommentText from '../Components/CommentText';
import CreateReplyForm from '../Components/CreateReplyForm';
import ReplyComment from './ReplyComment';

const colorForRatingFlag = ratingFlag =>
  FLAGS_TO_COLORS_RELATIONS[ratingFlag] || FLAGS_TO_COLORS_RELATIONS.without_flag;

const colorForCommentBody = ratingFlag =>
  COMMENTS_FLAGS_COLORS_RELATIONS[ratingFlag] || COMMENTS_FLAGS_COLORS_RELATIONS.without_flag;

const marginForComment = type => {
  return type === 'review_comment' ? '8px' : '4px';
};

const ReviewComment = ({
  reviewCommentId,
  descendantIds = [],
  onDelete,
  onUpdate,
  allowActions,
  isQuestionView,
  disabled
}) => {
  const dispatch = useDispatch();
  const deleteReply = useCallback(options => dispatch(deleteReplyComment(options)), [dispatch]);
  const updateReply = useCallback(options => dispatch(updateReplyComment(options)), [dispatch]);

  const clientInteractionsCommentsByIds = useSelector(
    state => state.uiClientInteractionPage.commentsByIds,
    isEqual
  );
  const commentsByIds = useSelector(state => state.commentsResource.byIds, isEqual);

  const currentReviewComment =
    clientInteractionsCommentsByIds[reviewCommentId] || get(commentsByIds, reviewCommentId, {});

  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);
  const [commentState, setCommentState] = useState({
    ...currentReviewComment,
    uploadedFiles: currentReviewComment?.uploadedFilesIds?.map(id => uploadedFilesByIds[id])
  });

  // * используется для хранения состояния которое нужно применить если при редактировании комментария была нажата кнопка отмены
  const [initialCommentState, setInitialCommentState] = useState(null);

  const editorText = commentState?.text;
  const [isEditing, setEditing] = useState(false);
  const [isEditReply, setEditReply] = useState(false);

  const currentUserPermissions = useSelector(state => getCurrentUserPermissions(state));
  const descendantCommentsByIds = orderBy(
    getCommentsFilteredByPermission({
      currentUserPermissions,
      comments: descendantIds.map(descendantId => get(commentsByIds, descendantId, {}))
    }),
    'createdAt'
  );

  const ratingFlag = get(commentState, 'metadata.ratingFlag', '');
  const isHidden = get(commentState, 'hidden', false);
  const commentType = get(commentState, 'commentType', 'review_comment');
  const parentId = get(commentState, 'parentId', '');

  const commentTimeStamp = commentState?.metadata?.timestamp;
  const editingCommentId = useSelector(state => state.uiClientInteractionPage.editingCommentId);

  if (isEmpty(commentState?.authorId)) return null;

  return (
    <>
      <StyledCol span={24} commentType={commentType}>
        <SCard
          bordered={isEditing ? '2px solid #c5d2fb' : `1px solid ${colorForRatingFlag(ratingFlag)}`}
          background={isEditing ? '#fffff' : colorForCommentBody(ratingFlag)}
          shadowed={
            isEditing
              ? 'inset 0px -1px 0px #597ef7, inset 0px 1px 0px #597ef7, inset -1px 0px 0px #597ef7, inset 1px 0px 0px #597ef7'
              : false
          }
        >
          <SRow type="flex" justify="space-between">
            {!(isNull(editingCommentId) && editingCommentId === reviewCommentId) && (
              <SCol span={24}>
                <CommentInfo
                  commentState={commentState}
                  isEditing={isEditing}
                  setEditing={setEditing}
                  isEditReply={isEditReply}
                  setEditReply={setEditReply}
                  editorText={editorText}
                  currentComment={currentReviewComment}
                  allowActions={allowActions}
                  onDelete={onDelete}
                  commentType={commentType}
                  commentTimeStamp={commentTimeStamp}
                  isQuestionView={isQuestionView}
                  initialCommentState={initialCommentState}
                  setInitialCommentState={setInitialCommentState}
                />
              </SCol>
            )}
            <SCol span={24}>
              <CommentText
                disabled={disabled}
                commentState={commentState}
                isEditing={isEditing}
                setCommentState={setCommentState}
                editorText={editorText}
                setEditing={setEditing}
                onUpdate={onUpdate}
                setInitialCommentState={setInitialCommentState}
                allowActions={allowActions}
                initialCommentState={initialCommentState}
                onDeleteComment={onDelete}
              />
            </SCol>
          </SRow>
        </SCard>
        {allowActions && (
          <CreateReplyForm
            parentId={parentId}
            isEditReply={isEditReply}
            setEditReply={setEditReply}
          />
        )}
      </StyledCol>
      {descendantCommentsByIds.map(descendantComment => {
        return (
          <ReplyComment
            key={descendantComment.id}
            replyCommentId={descendantComment.id}
            commentsByIds={commentsByIds}
            onDelete={deleteReply}
            onUpdate={updateReply}
            isParentHidden={isHidden}
            allowActions={allowActions}
          />
        );
      })}
    </>
  );
};

const StyledCol = styled(Col)`
  &.ant-col {
    width: 100%;
    &:nth-of-type(n + 2) {
      margin-top: ${({ commentType }) => `${marginForComment(commentType)}`};
    }
  }
`;

export default ReviewComment;
