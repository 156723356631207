import React from 'react';
import { INTEGRATIONS } from 'core/utils/constants';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import api, { ednaSynchronizationEndpoint } from 'core/api';
import { processJsonApiCollection } from 'core/jsonapi';
import { Col, message, Modal, Row, Spin, Typography } from 'antd';
import { saveBackgroundJob } from '../backgroundJob/operations';
import parseOptionsForApi from '../../../core/utils/parseOptionsForApi';
import { decryptedData } from '../naumenIntegration/operations';

let integration = null;
let errorInstall = true;
const modal = Modal;
export const createEdnaIntegration = ({
  name,
  endpoint,
  clientSecret,
  username,
  password,
  organization_id
}) => async dispatch => {
  try {
    await (async function() {
      integration = await dispatch(
        integrationsResource.operations.create({
          name,
          endpoint,
          clientSecret,
          password,
          username,
          integrationType: INTEGRATIONS.edna.type
        })
      );
      modal.confirm({
        title: 'Внимание',
        content: (
          <Row>
            <Col>
              <Typography.Text>Подождите, идет установка интеграции</Typography.Text>
            </Col>
            <Col style={{ width: '100%', textAlign: 'center' }}>
              <Spin />
            </Col>
          </Row>
        ),
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { display: 'none' } },
        okText: 'Ok',
        closable: false,
        maskClosable: false
      });
      await api
        .createEdnaIntegration(
          parseOptionsForApi({
            name,
            endpoint,
            clientSecret,
            password,
            username,
            state: organization_id,
            integrationType: INTEGRATIONS.edna.type
          })
        )
        .then(() => (errorInstall = true))
        .catch(() => (errorInstall = false));
    })();
  } catch (error) {
    console.log(error);
  } finally {
    modal.destroyAll();
    console.log(errorInstall);
    if (integration && errorInstall) {
      dispatch(saveBackgroundJob(integration.currentBackgroundJob));
      return integration.id;
    }
    dispatch(integrationsResource.operations.deleteById({ id: integration.id }));
  }
};

export const syncDataWithEdna = ({ id, fetchFrom }) => async dispatch => {
  await fetch(`${ednaSynchronizationEndpoint}?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      integration_id: id,
      fetch_from: fetchFrom
    })
  });
};
export const getStatusConnectEdna = async id => {
  try {
    const response = await api.getStatusConnectEdna(decamelizeKeys({ integrationId: id }));
    return response.body;
  } catch (error) {}
};
export const getEdnaDb = async id => {
  try {
    const response = await api.getEdnaDb(decamelizeKeys({ id }));
    const { data } = response.body;
    const result = {
      username: data?.username,
      password: decryptedData(data?.password)
    };
    return result;
  } catch (error) {}
};
export const setEdnaDb = async (id, otherFields) => {
  try {
    const response = await api.setEdnaDb(
      { integration_id: id },
      decamelizeKeys({
        username: otherFields.username,
        password: otherFields.password
      })
    );
    const resources = processJsonApiCollection(response.body.data);
    return resources;
  } catch (error) {
    message.error(error);
  }
};

export const getEntityCustomFieldsEdna = ({ id }) => async dispatch => {
  try {
    const response = await api.getEntityCustomFieldsEdna(decamelizeKeys({ integrationId: id }));
    const resources = processJsonApiCollection(response.body.data);
    return resources;
  } catch (error) {}
};
export const connectCustomFieldEdna = ({ fieldId }) => async dispatch => {
  try {
    await api.connectEntityCustomFieldsEdna(parseOptionsForApi({ fieldId }));
    return true;
  } catch (error) {}
};

export const connectAllCustomFieldsEdna = ({ id, all }) => async dispatch => {
  try {
    await api.connectAllEntityCustomFieldsEdna(
      parseOptionsForApi({ integrationId: id, connect: all })
    );
    return true;
  } catch (error) {}
};

export const disconnectCustomFieldEdna = ({ fieldId }) => async dispatch => {
  try {
    await api.disconnectEntityCustomFieldsEdna(parseOptionsForApi({ fieldId }));
    return true;
  } catch (error) {}
};
