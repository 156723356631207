export default {
  aiSettingsPage: {
    title: 'Настройки AI',
    tableColumnsTitle: {
      creationDate: 'Дата создания',
      lastLaunchDate: 'Дата пос. запуска',
      status: 'Статус',
      type: 'Тип',
      checks: 'Проверок',
      inProgress: 'В работе',
      successful: 'Успешно',
      withError: 'С ошибкой',
      duration: 'Прод-ть',
      direction: 'Направление',
      phoneNumber: 'Номер телефона',
      dateTime: 'Дата и время',
      taskId: 'Id'
    },
    taskStatus: {
      readyToLaunch: 'Готов к запуску',
      inProcess: 'В процессе',
      validationError: 'Ошибка валидации',
      completed: 'Завершена',
      inQueue: 'В очереди',
      transcription: 'Транскрибация',
      analysis: 'Анализ',
      analysisError: 'Ошибка анализа',
      transcriptionError: 'Ошибка транскрибации'
    },
    taskSettings: {
      selectDataset: 'Выберите Dataset',
      selectCustomFields: 'Выберите кастомные поля',
      customField: 'Кастомное поле',
      rating: 'Оценка',
      selectAiRatingForm: 'Выберите форму оценки AI',
      checkStatus: 'Статус проверки',
      addCondition: 'Добавить условие',
      communicationFilter: 'Фильтр коммуникаций',
      operators: 'Операторы',
      contactStatus: 'Статус контакта',
      department: 'Отдел',
      levels: 'Уровни',
      period: 'Период',
      regular: 'Регулярная',
      oneTime: 'Разовая',
      taskType: 'Тип задачи',
      disableEnable: 'Выключить / Включить',
      edit: 'Редактировать',
      newTask: 'Новая задача',
      pleaseEnterTaskName: 'Пожалуйста, введите название задачи',
      enterName: 'Введите название',
      export: 'Экспортировать',
      communicationList: 'Список коммуникаций',
      incoming: 'Входящий',
      outgoing: 'Исходящий',
      byStatus: 'По статусу'
    },
    operations: {
      operationSuccessfully: 'Операция успешно выполнена',
      operationError: 'Произошла ошибка',
      checkAIAssessmentFormOrPromptsValues:
        'Необходимо указать хотя бы одно значение в поле Форма оценки AI или Промпты',
      saveAiTask: `После сохранения задачи настоятельно не рекомендуется изменять промпты и форму оценки, чтобы обеспечить корректную работу QolioAI. Обратите внимание, что для успешного прохождения валидации все критерии в форме оценки и все поля должны быть связаны с промптами.`
    },
    processStatus: {
      completed: 'Завершена',
      process: 'В процессе',
      mistake: 'Ошибка',
      ready: 'Готов к запуску',
      all: 'Все'
    },
    tooltips: {
      validationError: `Возможные причины: \nИзменение в форме оценки \nИзменение кастомных полей \nНекорректные промпты`,
      analyzeError: 'Другие ошибки',
      allStatus: 'В рамках демо режима можно обработать только 100 коммуникаций ',
      transcriptionError: 'Ошибки транскрибации',
      copyTaskId: 'Скопировать Id задачи'
    },
    messages: {
      copyTaskIdSuccess: 'Id задачи скопирован'
    }
  }
};
