import React, { useCallback, useEffect, useRef, useState } from 'react';
import Player from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, pick } from 'lodash';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { SPlayer } from './styled';
import { setUpdatingUrl, setUrl, updatePlayerState } from '../../redux/ui/recordPlayer/reducer';
import AudioTrack from './AudioTrack';
import AudioControls from './AudioControls';

const Audio = ({ htmlString }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const player = useRef();
  const [bufferedPercentage, setBufferedPercentage] = useState(0);
  const { playbackRate, isPlaying, wasPlayed, forcePlayed, playedSeconds } = useSelector(
    state =>
      pick(state.uiRecordPlayer, [
        'playbackRate',
        'isPlaying',
        'wasPlayed',
        'forcePlayed',
        'playedSeconds'
      ]),
    isEqual
  );

  const setTime = useCallback(
    seconds => {
      if (player.current && player.current.seekTo) {
        player.current.seekTo(seconds, 'seconds');
      }
    },
    [player?.current]
  );

  useEffect(() => {
    const setInitState = async () => {
      const { search } = history.location;
      if (search) {
        const { t } = qs.parse(search, { ignoreQueryPrefix: true });
        if (t) {
          setTime(parseFloat(t));
          dispatch(
            updatePlayerState({
              playedSeconds: parseFloat(t),
              wasPlayed: false
            })
          );
        }
      }
    };
    setInitState();
  }, [history.location]);

  useEffect(() => {
    if (forcePlayed) {
      setTime(playedSeconds);
      dispatch(updatePlayerState({ forcePlayed: false }));
    }
  }, [playedSeconds]);

  const handleProgress = useCallback(async ({ playedSeconds, played, loaded }) => {
    if (playedSeconds === 0 && !wasPlayed) {
      return;
    }
    await dispatch(updatePlayerState({ playedSeconds, played }));
    setBufferedPercentage(loaded * 100);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setUrl(null));
      dispatch(setUpdatingUrl(true));
      dispatch(updatePlayerState({ isPlaying: false }));
    };
  }, [htmlString]);

  const onPlay = () => {
    dispatch(updatePlayerState({ wasPlayed: true }));
    // Удалить autoplay attr у аудио тега потому что на некоторых устройствах бывает двойной звук у аудио
    try {
      const audio = document.querySelector('audio');
      if (audio) {
        audio.removeAttribute('autoplay');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onError = error => {
    console.log('Произошла ошибка при загрузке или воспроизведении аудио', error);
  };

  return (
    <SPlayer>
      {htmlString && (
        <>
          <Player
            url={htmlString}
            onProgress={handleProgress}
            style={{ display: 'none' }}
            onDuration={duration => dispatch(updatePlayerState({ duration }))}
            loop={false}
            playing={isPlaying}
            playbackRate={parseFloat(playbackRate)}
            progressInterval={100}
            ref={player}
            // onReady={onReady}
            onError={onError}
            onPlay={onPlay}
            width={0}
            height={0}
          />
          <AudioTrack bufferedPercentage={bufferedPercentage} />
        </>
      )}
      <AudioControls recordUrl={htmlString} />
    </SPlayer>
  );
};

export default React.memo(Audio);
