export default {
  aiSettingsPage: {
    title: 'Impostazioni IA',
    tableColumnsTitle: {
      creationDate: 'Data di creazione',
      lastLaunchDate: 'Data ultimo lancio',
      status: 'Stato',
      type: 'Tipo',
      checks: 'Controlli',
      inProgress: 'In corso',
      successful: 'Riuscito',
      withError: 'Con errore',
      duration: 'Durata',
      direction: 'Direzione',
      phoneNumber: 'Numero di telefono',
      dateTime: 'Data e ora',
      taskId: 'Id'
    },
    taskStatus: {
      readyToLaunch: 'Pronto per il lancio',
      inProcess: 'In processo',
      validationError: 'Errore di validazione',
      completed: 'Completato',
      inQueue: 'In coda',
      transcription: 'Trascrizione',
      analysis: 'Analisi',
      analysisError: 'Errore di analisi',
      transcriptionError: 'Errore di trascrizione'
    },
    taskSettings: {
      selectDataset: 'Seleziona Dataset',
      selectCustomFields: 'Seleziona campi personalizzati',
      customField: 'Campo personalizzato',
      rating: 'Valutazione',
      selectAiRatingForm: 'Seleziona modulo di valutazione IA',
      checkStatus: 'Verifica stato',
      addCondition: 'Aggiungi condizione',
      communicationFilter: 'Filtro di comunicazione',
      operators: 'Operatori',
      contactStatus: 'Stato del contatto',
      department: 'Dipartimento',
      levels: 'Livelli',
      period: 'Periodo',
      regular: 'Regolare',
      oneTime: 'Una volta',
      taskType: 'Tipo di attività',
      disableEnable: 'Disabilita / Abilita',
      edit: 'Modifica',
      newTask: 'Nuova attività',
      pleaseEnterTaskName: "Inserisci il nome dell'attività",
      enterName: 'Inserisci nome',
      export: 'Esporta',
      communicationList: 'Lista di comunicazione',
      incoming: 'In entrata',
      outgoing: 'In uscita',
      byStatus: 'Per stato'
    },
    operations: {
      operationSuccessfully: 'Operazione riuscita',
      operationError: 'Errore di operazione',
      checkAIAssessmentFormOrPromptsValues:
        'È necessario specificare almeno un valore nel campo modulo di valutazione AI o Prompt',
      saveAiTask: `Dopo aver salvato l'attività, si consiglia vivamente di non modificare i prompt e il modulo di valutazione per garantire che QolioAI funzioni correttamente. Si noti che per superare correttamente la convalida, tutti i criteri nel modulo di valutazione e tutti i campi devono essere associati ai prompt.`
    },
    processStatus: {
      completed: 'Finito',
      process: 'Nel corso di',
      mistake: 'Errore',
      ready: 'Pronto per il lancio',
      all: 'Solo'
    },
    tooltips: {
      validationError: `Possibili cause: \nModifica del modulo di valutazione \nModifica dei campi personalizzati \nPrompt errati`,
      analyzeError: 'Altri errori',
      allStatus: "Solo 100 comunicazioni possono essere elaborate all'interno della modalità demo",
      transcriptionError: 'Errore di trascrizione',
      copyTaskId: 'Copia Id attività'
    },
    messages: {
      copyTaskIdSuccess: 'Id attività copiato'
    }
  }
};
