import React, { useEffect, useRef, useState } from 'react';
import { Select, DatePicker, Input, Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { getUserName } from 'components/UserPreview/getUserName';
import {
  actions as actionsV3,
  operations as operationsV3
} from 'redux/lists/uploadedCallsListReducerV3';
import styled from 'styled-components';
import { usersResource } from 'redux/resources/users';
import { PERMISSIONS } from 'core/utils/constants';
import { withTranslation } from 'react-i18next';
import { getUsersWithRoleAndUnit } from 'redux/selectors/users';
import { selectSearch } from 'core/utils/selectSearch';
import { massEditingCalls, startEditingCalls } from 'redux/ui/uploadCallsPage/reducer';
import { utils } from 'react-media-player';
import ClientInteractionLink from 'components/ClientInteractionLink';
import Text from 'antd/lib/typography/Text';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer';
import {
  CommunicationTypeContainer,
  DirectionContainer,
  OperatorContainer,
  RequiredFieldSpan,
  SCheckbox,
  TitleContainer
} from '../styled';
import { DeactivatedUserLinkToCommunication } from '../../ClientInteractionsPage/components/DeactivatedLinkToCommunication';
import STable from '../components/STable';
import SText from '../../../components/Standard/SText';

const { Option } = Select;

const borderGreen = {
  border: '1px solid green'
};

const borderRed = {
  border: '1px solid red'
};

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 0 0;
  //margin: 0 0 10px 0;
  .ant-select.ant-select-enabled {
    width: 100% !important;
  }

  .ant-calendar-picker {
    min-width: 170px !important;
  }
`;

const EditableUploadedCallsTable = ({
  usersByIds,
  users,
  calls,
  totalCount,
  listPage,
  listQuery,
  loading,
  loadCallsV3,
  loadUsers,
  updatePage,
  t,
  callsByIds,
  massAction,
  allSelected,
  toggleAll,
  isSelected,
  toggle,
  selected,
  massActionOperatorId,
  setMassActionOperatorId,
  massActionDirection,
  setMassActionDirection,
  getOrganizationId,
  handleCancelEditing
}) => {
  const dispatch = useDispatch();
  const prevProps = useRef({ listPage, listQuery }).current;
  const currentTimeZone = moment().format('Z');
  const [isLoadingCalls, setIsLoadingCalls] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);

  const handleTableChange = pagination => {
    updatePage({ size: pagination.pageSize.toString(), number: pagination.current.toString() });
  };

  useEffect(() => {
    const loadCalls = async () => {
      setIsLoadingUsers(true);
      await loadCallsV3({
        page_size: listPage.size,
        page_number: listPage.number,
        organization_id: getOrganizationId,
        timezone: currentTimeZone
      });
      setIsLoadingCalls(false);
    };

    const loadUsersData = async () => {
      setIsLoadingUsers(true);
      await loadUsers({ include: 'unit,role', pagination: 'false', sort: 'last_name' });
      setIsLoadingUsers(false);
    };

    // Вызываем функции загрузки данных
    loadCalls();
    loadUsersData();
  }, []);

  const isTableParamsUpdated = prevProps => {
    return prevProps.listPage !== listPage;
  };

  useEffect(() => {
    if (isTableParamsUpdated(prevProps)) {
      const loadCalls = async () => {
        setIsLoadingUsers(true);
        await loadCallsV3({
          page_size: listPage.size,
          page_number: listPage.number,
          organization_id: getOrganizationId,
          timezone: currentTimeZone
        });
        setIsLoadingCalls(false);
      };

      const loadUsersData = async () => {
        setIsLoadingUsers(true);
        await loadUsers({ include: 'unit,role', pagination: 'false', sort: 'last_name' });
        setIsLoadingUsers(false);
      };

      // Вызываем функции загрузки данных
      loadCalls();
      loadUsersData();
    }
  }, [listPage, listQuery]);

  const handleCheckEmptyField = (value, editingValue) => {
    if (!value && !editingValue) {
      return borderRed;
    }
  };

  // Смена цветов рамок у полей при их редактировании или если они пустые
  const handleChangeBorderColor = (currentCallId, currentValue, action) => {
    if (action === 'operator') {
      // Если поле не заполнено и не редактируется подсвечиваем красным по умолчанию
      const checkEmptyField = handleCheckEmptyField(
        currentValue,
        callsByIds[currentCallId]?.operatorId
      );

      if (checkEmptyField) {
        return checkEmptyField;
      }

      // Если звонок редактируется и содержит operatorId
      if (callsByIds[currentCallId]?.operatorId) {
        if (
          callsByIds[currentCallId].operatorId !== 'null' &&
          callsByIds[currentCallId].operatorId !== currentValue
        ) {
          return borderGreen;
        }
        if (callsByIds[currentCallId].operatorId === 'null') {
          return borderRed;
        }
      }
    }
    if (action === 'direction') {
      // Если поле не заполнено и не редактируется подсвечиваем красным по умолчанию
      const checkEmptyField = handleCheckEmptyField(
        currentValue,
        callsByIds[currentCallId]?.direction
      );

      if (checkEmptyField) {
        return checkEmptyField;
      }

      // Если звонок редактируется и содержит direction
      if (callsByIds[currentCallId]?.direction) {
        if (
          callsByIds[currentCallId].direction !== 'null' &&
          callsByIds[currentCallId].direction !== currentValue
        ) {
          return borderGreen;
        }
        if (callsByIds[currentCallId].direction === 'null') {
          return borderRed;
        }
      }
    }

    if (action === 'startedAt') {
      // Если поле не заполнено и не редактируется подсвечиваем красным по умолчанию
      const checkEmptyField = handleCheckEmptyField(
        currentValue,
        callsByIds[currentCallId]?.startedAt
      );

      if (checkEmptyField) {
        return checkEmptyField;
      }

      // Если звонок редактируется и содержит startedAt
      if (callsByIds[currentCallId]?.startedAt) {
        if (
          callsByIds[currentCallId].startedAt !== 'null' &&
          callsByIds[currentCallId].startedAt !== currentValue
        ) {
          return borderGreen;
        }
        if (callsByIds[currentCallId].startedAt === 'null') {
          return borderRed;
        }
      }
    }

    if (action === 'clientPhoneNumber') {
      // Если поле не заполнено и не редактируется подсвечиваем красным по умолчанию
      const checkEmptyField = handleCheckEmptyField(
        currentValue,
        callsByIds[currentCallId]?.clientPhoneNumber
      );

      if (checkEmptyField) {
        return checkEmptyField;
      }

      const callsByIdsClientPhoneNumber = callsByIds[currentCallId]?.clientPhoneNumber
        ? callsByIds[currentCallId]?.clientPhoneNumber.trim()
        : callsByIds[currentCallId]?.clientPhoneNumber;
      // const callsByIdsClientPhoneNumber = callsByIds[currentCallId]?.clientPhoneNumber.trim();
      const clientPhoneNumber =
        callsByIdsClientPhoneNumber === '' ? 'null' : callsByIdsClientPhoneNumber;

      // Если звонок редактируется и содержит clientPhoneNumber
      if (clientPhoneNumber) {
        if (clientPhoneNumber !== 'null' && clientPhoneNumber !== currentValue) {
          return borderGreen;
        }
        if (clientPhoneNumber === 'null') {
          return borderRed;
        }
      }
    }
  };

  const getOperatorId = (record, user, operatorId) => {
    if (operatorId) {
      return callsByIds[record?.id].operatorId;
    }
    if (record?.operatorId) {
      return record?.operatorId;
      // return getUserName({ user });
    }
    return t('components.userPreview.userNotInstalled');
  };

  const getDirection = (recordId, record, direction) => {
    if (direction) {
      return callsByIds[recordId].direction;
    }
    if (record?.direction) {
      return record?.direction;
    }
    return t('uploadCallsPage.table.columns.directionTitle');
  };

  const getStartedAt = (recordId, record, startedAt) => {
    if (startedAt) {
      return startedAt === 'null' ? '' : moment(startedAt);
    }
    return moment(record?.startedAt);
  };

  const getClientPhoneNumber = (recordId, record, clientPhoneNumber) => {
    if (clientPhoneNumber) {
      return clientPhoneNumber === 'null' ? '' : clientPhoneNumber;
    }
    return record?.clientPhoneNumber;
  };

  const handleChangeMassActionRow = (record, user, action) => {
    const { id } = record;
    const isSelect = selected.find(item => item === id);

    if (action === 'operator') {
      if (isSelect) {
        return massActionOperatorId && setMassActionOperatorId(null);
      }
      return user?.id;
    }
    if (action === 'direction') {
      if (isSelect) {
        return massActionDirection && setMassActionDirection(null);
      }
      return record?.direction;
    }
  };

  const bytesToMB = bytes => {
    return (bytes / (1024 * 1024)).toFixed(1);
  };

  // console.log(
  //   'massActionOperatorId', massActionOperatorId,
  //   '\nmassActionDirection', massActionDirection,
  //   '\nmassAction', massAction,
  //   '\ncallsByIds', callsByIds,
  //   '\nselected', selected
  // );

  const columns = [
    {
      key: 'operators', // Оператор
      dataIndex: 'operatorId',
      width: 300,
      title: () => {
        return (
          <TitleContainer>
            <OperatorContainer>
              <Text>
                {t('uploadCallsPage.table.columns.operator')}
                <RequiredFieldSpan>*</RequiredFieldSpan>
              </Text>
            </OperatorContainer>
            <SelectContainer>
              <SCheckbox checked={allSelected} onClick={() => toggleAll(true)} />
              <Select
                placeholder={t('uploadCallsPage.table.columns.selectOperator')}
                disabled={selected.length <= 1}
                value={null}
                style={{ width: '100%', marginLeft: '10px' }}
                onChange={value => {
                  if (selected.length >= 2 && !massActionOperatorId) {
                    dispatch(massEditingCalls({ callsIds: selected, operatorId: value || 'null' }));
                    setMassActionOperatorId(value);
                  }
                }}
                showSearch
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'label' })
                }
              >
                {users
                  .filter(user =>
                    get(user, 'role.permissions', []).includes(
                      PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION
                    )
                  )
                  .map(user => (
                    <Option key={user.id} value={user.id} label={getUserName({ user })}>
                      {getUserName({ user })}
                    </Option>
                  ))}
              </Select>
            </SelectContainer>
          </TitleContainer>
        );
      },
      render: (operatorId, record) => {
        const user = usersByIds[operatorId];
        return (
          <SelectContainer>
            <SCheckbox checked={isSelected(record.id)} onClick={() => toggle(record.id)} />
            {!massActionOperatorId && (
              <Select
                placeholder={t('uploadCallsPage.table.columns.operator')}
                style={{
                  ...handleChangeBorderColor(record?.id, record?.operatorId, 'operator'),
                  marginLeft: '10px',
                  width: '100%'
                }}
                onChange={value =>
                  dispatch(startEditingCalls({ callId: record.id, operatorId: value || 'null' }))
                }
                value={
                  isLoadingCalls || isLoadingUsers
                    ? getUserName({ user })
                    : getOperatorId(record, user, callsByIds[record?.id]?.operatorId)
                }
                showSearch
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'label' })
                }
              >
                {users
                  .filter(user =>
                    get(user, 'role.permissions', []).includes(
                      PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION
                    )
                  )
                  .map(user => (
                    <Option key={user.id} value={user.id} label={getUserName({ user })}>
                      {getUserName({ user })}
                    </Option>
                  ))}
              </Select>
            )}
            {massActionOperatorId && massAction && (
              <Select
                placeholder={t('uploadCallsPage.table.columns.operator')}
                style={{
                  ...handleChangeBorderColor(record?.id, record?.operatorId, 'operator'),
                  marginLeft: '10px',
                  width: '100%'
                }}
                onChange={value =>
                  dispatch(startEditingCalls({ callId: record.id, operatorId: value || 'null' }))
                }
                value={handleChangeMassActionRow(record, user, 'operator')}
                // defaultValue={handleChangeMassActionRow(record, user, 'operator')}
                // defaultValue={selected.find(item => item === record.id) ? massActionOperatorId && setMassActionOperatorId(null) : user?.id}
                showSearch
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'label' })
                }
              >
                {users
                  .filter(user =>
                    get(user, 'role.permissions', []).includes(
                      PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION
                    )
                  )
                  .map(user => (
                    <Option key={user.id} value={user.id} label={getUserName({ user })}>
                      {getUserName({ user })}
                    </Option>
                  ))}
              </Select>
            )}
          </SelectContainer>
        );
      }
    },
    {
      key: 'direction', // Направление/Тип звонка
      dataIndex: 'direction',
      width: 120,
      title: () => {
        return (
          <TitleContainer>
            <DirectionContainer>
              <Text>
                {t('uploadCallsPage.table.columns.direction')}
                <RequiredFieldSpan>*</RequiredFieldSpan>
              </Text>
            </DirectionContainer>
            <SelectContainer>
              <Select
                placeholder={t('uploadCallsPage.table.columns.directionTitle')}
                disabled={selected.length <= 1}
                style={{ width: '100%' }}
                value={null}
                onChange={value => {
                  if (selected.length >= 2 && !massActionDirection) {
                    dispatch(massEditingCalls({ callsIds: selected, direction: value || 'null' }));
                    setMassActionDirection(value);
                  }
                }}
              >
                <Option value="incoming">
                  {t('constants.clientInteractionDirections.incoming')}
                </Option>
                <Option value="outcoming">
                  {t('constants.clientInteractionDirections.outcoming')}
                </Option>
              </Select>
            </SelectContainer>
          </TitleContainer>
        );
      },
      render: (text, record) => {
        return (
          <TitleContainer>
            <SelectContainer>
              {!massActionDirection && (
                <Select
                  placeholder={t('uploadCallsPage.table.columns.direction')}
                  style={{
                    ...handleChangeBorderColor(record?.id, record?.direction, 'direction'),
                    width: '100%'
                  }}
                  value={getDirection(record?.id, record, callsByIds[record?.id]?.direction)}
                  onChange={value =>
                    dispatch(startEditingCalls({ callId: record.id, direction: value || 'null' }))
                  }
                >
                  <Option value="incoming">
                    {t('constants.clientInteractionDirections.incoming')}
                  </Option>
                  <Option value="outcoming">
                    {t('constants.clientInteractionDirections.outcoming')}
                  </Option>
                </Select>
              )}
              {massActionDirection && massAction && (
                <Select
                  placeholder={t('uploadCallsPage.table.columns.direction')}
                  style={{
                    ...handleChangeBorderColor(record?.id, record?.direction, 'direction'),
                    width: '100%'
                  }}
                  value={handleChangeMassActionRow(record, null, 'direction')}
                  onChange={value =>
                    dispatch(startEditingCalls({ callId: record.id, direction: value || 'null' }))
                  }
                >
                  <Option value="incoming">
                    {t('constants.clientInteractionDirections.incoming')}
                  </Option>
                  <Option value="outcoming">
                    {t('constants.clientInteractionDirections.outcoming')}
                  </Option>
                </Select>
              )}
            </SelectContainer>
          </TitleContainer>
        );
      }
    },
    {
      key: 'startedAt', // Дата звонка
      dataIndex: 'startedAt',
      width: 100,
      title: (
        <TitleContainer>
          {t('uploadCallsPage.table.columns.startedAt')}
          <RequiredFieldSpan>*</RequiredFieldSpan>
        </TitleContainer>
      ),
      render: (text, record) => {
        return (
          <SelectContainer>
            <DatePicker
              style={{
                ...handleChangeBorderColor(record?.id, record?.startedAt, 'startedAt'),
                width: '100%'
              }}
              allowClear={false}
              showTime
              value={getStartedAt(record?.id, record, callsByIds[record?.id]?.startedAt)}
              placeholder={t('uploadCallsPage.table.columns.startedAtPlaceholder')}
              format="DD/MM/YYYY, HH:mm"
              disabledDate={current => current && current > moment().endOf('day')}
              disabledTime={current => {
                if (current) {
                  const today = moment();
                  const selectedHour = current.hours();

                  // Кейс допустим дата сегодня, и время сейчас 10:05, выбираем 9 часов итог - нельзя выбрать минуты больше 5
                  // Даём возможность выбирать все минуты если текущий час больше выбраного чтобы избежать кейс выше
                  if (current.isSame(today, 'day') && selectedHour < today.hours()) {
                    return {
                      disabledHours: () => [...Array(24).keys()].splice(today.hours() + 1)
                    };
                  }
                  if (current.isSame(today, 'day')) {
                    return {
                      disabledHours: () => [...Array(24).keys()].splice(today.hours() + 1),
                      disabledMinutes: () => [...Array(60).keys()].splice(today.minutes() + 1)
                    };
                  }
                }
              }}
              onChange={value => {
                const currentDate = moment();
                const selectedDate = moment(value);

                // Представим что сейчас 10:00 утра, выбираем дату на 1 день меньше текущей, после чего выбираем время 23:00 и выбираем текущую дату, по итогу время будет выбранно в будущем
                // Тут происходит проверка на кейс описанный выше, и в этом случае возвращается текущяя дата и время
                if (currentDate.isSame(selectedDate, 'day') && selectedDate > currentDate) {
                  return dispatch(
                    startEditingCalls({
                      callId: record.id,
                      startedAt: currentDate ? currentDate.toISOString() : 'null'
                    })
                  );
                }
                dispatch(
                  startEditingCalls({
                    callId: record.id,
                    startedAt: value ? value.toISOString() : 'null'
                  })
                );
              }}
            />
          </SelectContainer>
        );
      }
    },
    {
      key: 'clientPhoneNumber', // Номер телефона
      dataIndex: 'clientPhoneNumber',
      width: 200,
      title: (
        <TitleContainer>
          {t('uploadCallsPage.table.columns.clientPhoneNumber')}
          <RequiredFieldSpan>*</RequiredFieldSpan>
        </TitleContainer>
      ),
      render: (text, record) => {
        return (
          <Tooltip
            title={getClientPhoneNumber(
              record?.id,
              record,
              callsByIds[record?.id]?.clientPhoneNumber
            )}
          >
            <Input
              value={getClientPhoneNumber(
                record?.id,
                record,
                callsByIds[record?.id]?.clientPhoneNumber
              )}
              // value={handleMassActionDefaultValue(record, null, 'clientPhoneNumber')}
              placeholder={t('uploadCallsPage.table.columns.clientPhoneNumber')}
              style={{
                ...handleChangeBorderColor(
                  record?.id,
                  record?.clientPhoneNumber,
                  'clientPhoneNumber'
                ),
                width: '100%'
              }}
              onChange={e => {
                const inputValue = e.target.value;
                const clientPhoneNumber = inputValue || 'null';
                dispatch(
                  startEditingCalls({
                    callId: record.id,
                    clientPhoneNumber
                  })
                );
              }}
            />
          </Tooltip>
        );
      }
    },
    {
      key: 'communicationType', // Звонок
      dataIndex: 'communicationType',
      width: 100,
      title: t('uploadCallsPage.table.columns.communicationType'),
      render: (
        type,
        { reviewId, operatorId, id, communicationType, clientInteractionType, fileName }
      ) => {
        const user = usersByIds[operatorId];
        const isActive = user?.active;
        return (
          (isActive && (
            <CommunicationTypeContainer>
              <ClientInteractionLink
                communication={{ communicationType, clientInteractionType }}
                isReview={!!reviewId}
                id={reviewId || id}
                style={{ width: '24px', height: '24px', display: 'block', marginRight: '10px' }}
              />
              <Tooltip title={fileName}>
                <SText ellipsis>{fileName}</SText>
              </Tooltip>
            </CommunicationTypeContainer>
          )) ||
          (!isActive && (
            <DeactivatedUserLinkToCommunication>
              <CommunicationTypeContainer>
                <ClientInteractionLink
                  style={{
                    width: '24px',
                    height: '24px',
                    display: 'block',
                    color: 'var(--blue_2)',
                    marginRight: '10px'
                  }}
                  communication={{ communicationType, clientInteractionType }}
                  isReview={!!reviewId}
                  id={reviewId || id}
                />
                <Tooltip title={fileName}>
                  <SText ellipsis>{fileName}</SText>
                </Tooltip>
              </CommunicationTypeContainer>
            </DeactivatedUserLinkToCommunication>
          ))
        );
      }
    },
    {
      key: 'fileSize', // Размер
      dataIndex: 'fileSize',
      width: 80,
      title: t('uploadCallsPage.table.columns.fileSize'),
      render: (text, record) => {
        const fileSizeBytes = text || record?.fileSize;
        const fileSizeMB = bytesToMB(fileSizeBytes) || 0;
        return <Text>{fileSizeBytes ? `${fileSizeMB} MB` : ''}</Text>;
      }
    },
    {
      key: 'duration', // Длина звонка
      dataIndex: 'duration',
      width: 80,
      title: t('uploadCallsPage.table.columns.duration'),
      render: text => {
        return <span>{utils.formatTime(text) || '-'}</span>;
      }
    }
  ];

  return (
    <Col span={24}>
      <Row style={{ padding: '25px' }}>
        <STable
          size="small"
          bordered
          rowKey="id"
          dataSource={calls}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            pageSize: parseInt(listPage.size),
            current: parseInt(listPage.number),
            total: totalCount,
            // Если необходимо сохранять отредактированые звонки между разными страницами пагинации то нужно убрать onChange
            onChange: handleCancelEditing
          }}
          onChange={handleTableChange}
          scroll={{ y: 'calc(100vh - 300px)' }}
          loading={loading}
        />
      </Row>
      {/* Подключаем компонент который отвечат за карточку звонка, для последующего вывода звонка */}
      <ClientInteractionDrawer shouldLoad />
    </Col>
  );
};

const mapStateToProps = (state, ownProps) => {
  const calls = state.uploadedCallsListV3.ids
    .map(id => state.phoneCallsResourceV3.byIds[id])
    .filter(call => !!call);

  const users = Object.values(getUsersWithRoleAndUnit(state));

  const operatorIdsFromCalls = calls.map(call => call.operatorId);
  const operatorIdsFromUsers = users.map(user => user.id);

  const loadingUsers = operatorIdsFromCalls.every(operatorId =>
    operatorIdsFromUsers.includes(operatorId)
  );

  const loading =
    state.uploadedCallsListV3.loading ||
    state.phoneCallsResourceV3.loading ||
    state.phoneCallsResourceV3.updateByIdStarted ||
    state.phoneCallsResourceV3.deleteByIdStarted;

  return {
    loading,
    calls,
    users,
    totalCount: state.uploadedCallsListV3.totalCount,
    usersByIds: state.usersResource.byIds,
    listPage: state.uploadedCallsListV3.page,
    listQuery: state.uploadedCallsListV3.query,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePage: pagination => dispatch(actionsV3.updatePage(pagination)),
    ...bindActionCreators(
      {
        loadUsers: usersResource.operations.load,
        loadCallsV3: operationsV3.load
      },
      dispatch
    )
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditableUploadedCallsTable))
);
